import { NETWORK } from '@arianee/arianeejs';

const WHITELABEL_HOSTNAMES_TO_CHAIN: Record<string, NETWORK> = {
  'test.arian.ee': NETWORK.testnet,
  'arian.ee': NETWORK.mainnet,
  'testnet.aria.fyi': NETWORK.testnet,
  'arialabs.arian.ee': NETWORK.mainnet,
  'poa.leclubleaderprice.fr': NETWORK.mainnet,
  'iwc-sokol.arianee.net': NETWORK.testnet,
  'poa.iwc.com': NETWORK.mainnet,
  'panerai-sokol.arianee.net': NETWORK.testnet,
  'poa.panerai.com': NETWORK.mainnet,
  'poa.yslbeauty.com': NETWORK.mainnet,
  'polygon.yslbeauty.com': NETWORK.ysl,
  'innovation-day.arian.ee': NETWORK.polygon,
  'poly.arian.ee': NETWORK.polygon,
  'stadetoulousain.arian.ee': NETWORK.stadetoulousain
};

export const getChainFromWhitelabelHostname = (hostname: string) : NETWORK | null => {
  return WHITELABEL_HOSTNAMES_TO_CHAIN[hostname.toLowerCase()] || null;
};
