import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

import {Button} from '../../../../../components/redesign/button/models/button.model';
import {Icon} from '../../../../../components/redesign/icon/models/icon.model';
import {IsAuthGuard} from '../../../../../guards/isAuth.guard';
import {SharingService} from '../../../../../providers/sharing-service/sharing.service';

@Component({
	selector: 'app-transfer-proof-modal',
	templateUrl: './transfer-proof-modal.component.html',
	styleUrls: ['./transfer-proof-modal.component.scss'],
})
export class TransferProofModalComponent implements OnInit {
	@Input() type: 'transfer' | 'proof';
	@Input() header: { title: string; icon: Icon };
	@Input() linkCodeCreation: () => Promise<{
		certificateId: number;
		link: string;
		passphrase: string;
	}>;

	@Input() isWeb: boolean;

	displayLoader = false;
	qrCodeLink: string;

	modal: HTMLIonModalElement;

	shareProofButton: Button;

	transferOwnershipButton: Button;

	constructor(
		private sharingService: SharingService,
		private translateService: TranslateService,
		protected modalCtrl: ModalController,
		protected authGuard: IsAuthGuard,
	) {}

	async ngOnInit() {
		this.modal = await this.modalCtrl.getTop();

		const authenticated = await this.authGuard.isAuth();
		if (!authenticated) {
			await this.modal.dismiss();
			return;
		}

		await this.createLinkCode();
		this.initButtons();
	}

	async createLinkCode() {
		this.displayLoader = true;
		this.qrCodeLink = (await this.linkCodeCreation()).link;
		this.displayLoader = false;
	}

	onCloseModal(): void {
		this.modalCtrl.dismiss({
			dismissed: true,
		});
	}

	onShareProof() {
		this.sharingService.shareLink(
			'Share.subjectProof',
			'Share.messageProof',
			this.qrCodeLink,
		);
	}

	onTransferOwnership() {
		this.sharingService.shareLink(
			'Share.subjectTransfer',
			'Share.messageTransfer',
			this.qrCodeLink,
		);
	}

	private initButtons() {
		this.shareProofButton = {
			title: {
				text: this.translateService.instant('TransferCertificate.share'),
			},
			buttonType: 'primary',
			loadingText: null,
			loading: false,
			disabled: false,
			actionFinished: false,
		};

		this.transferOwnershipButton = {
			title: {
				text: this.translateService.instant('TransferCertificate.transfer'),
			},
			buttonType: 'primary',
			loadingText: null,
			loading: false,
			disabled: false,
			actionFinished: false,
		};
	}
}
