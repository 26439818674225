import { AfterViewInit, Component, Input, Renderer2, ViewChild } from '@angular/core';
// @ts-ignore
const qrcode = require('qrcode');

// example <angular-qr-code link="http://arian.ee/id,passphrase" ></angular-qr-code>

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent implements AfterViewInit {
    @Input('link') link: string;
    @ViewChild('qrcodeElement', { static: true }) qrcodeElement;

    constructor (private renderer: Renderer2) {
    }

    ngAfterViewInit (): void {
      qrcode.toDataURL(this.qrcodeElement.nativeElement, this.link, {
        errorCorrectionLevel: 'L',
        margin: 0,
        width: 160,
        height: 160
      });
    }
}
