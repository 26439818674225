import { CertificateSummary } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary';
import { get } from 'lodash';

import { brandImageLogo } from '../../../../poap-detail/utils/poap-utils';
import { MappedCertificate } from '../../../models/mapped-certificate.model';
import { mapCertificateSummaryToTransparencyItems } from '../transparency/transparency-mapper';

function getCertificateMedias(certificate: CertificateSummary) {
	const oldCertificateExists =
		certificate.content.data.pictures !== undefined &&
		certificate.content.data.pictures.length > 0;
	const oldCertificateData =
		oldCertificateExists &&
		certificate.content.data.pictures.map((picture, index) => {
			return {
				type: 'product',
				mediaType: 'picture',
				url: picture.src,
				preview: null,
				order: index + 1,
			};
		});
	return oldCertificateExists
		? oldCertificateData
		: certificate.content.data.medias;
}

// @TODO mapper should support i18n
export async function certificateSummaryMapper(
	certificate: CertificateSummary,
): Promise<MappedCertificate> {
	const hasData = certificate.content.data;
	const hasIdentityData =
		certificate.issuer &&
		certificate.issuer.identity &&
		certificate.issuer.identity.data;
	return {
		id: certificate.certificateId,
		name: (hasData && certificate.content.data.name) || 'item name is missing',
		description: hasData ? certificate.content.data.description : null,
		model: hasData ? certificate.content.data.model : null,
		medias: hasData ? getCertificateMedias(certificate) : [],
		isPassport:
			certificate.content.data &&
			certificate.content.data.subCategory !== 'Digital Asset',
		isOwner: certificate.owner ? certificate.owner.isOwner : null,
		isRequestable: certificate.isRequestable,
		externalContents: hasData
			? [...(certificate.content.data.externalContents || [])]
			: [],
		transparencyItems: await mapCertificateSummaryToTransparencyItems(
			certificate,
		),
		serialNumber: hasData ? certificate.content.data.serialnumber : null,
		isContentAuthentic: certificate.content.isAuthentic,
		identity:
			certificate.issuer && certificate.issuer.identity
				? {
						address: certificate.issuer.identity.address,
						brandName: hasIdentityData
							? certificate.issuer.identity.data.name
							: null,
						brandDescription: hasIdentityData
							? certificate.issuer.identity.data.description
							: null,
						brandLogoHeaderReversed:
							brandImageLogo(certificate.issuer.identity, [
								'brandLogoHeaderReversed',
								'brandLogoHeader',
							]) ||
							'/assets/imgs/default-images/Brand_Logo_Header_White_V1.png',
						brandLogoHeader: brandImageLogo(certificate.issuer.identity, [
							'brandLogoHeader',
						]),
						certificateBackgroundPicture: brandImageLogo(
							certificate.issuer.identity,
							['certificateBackgroundPicture'],
						),
						brandLogoSquare: brandImageLogo(certificate.issuer.identity, [
							'brandLogoSquare',
						]),
						externalContents: [
							...get(certificate.issuer.identity.data, 'externalContents', []),
						],
						socialMedias: [
							...get(certificate.issuer.identity.data, 'socialmedia', []),
						],
						itemBackgroundPicture: brandImageLogo(certificate.issuer.identity, [
							'itemBackgroundPicture',
						]),
						isIdentityAuthentic: get(
							certificate.issuer.identity,
							'isAuthentic',
							false,
						),
						isIdentityVerified: get(
							certificate.issuer.identity,
							'isApproved',
							false,
						),
				  }
				: null,
	};
}
