import { Injectable } from '@angular/core';
import { JsonRpcResponse } from '@walletconnect/jsonrpc-types';
import { SessionTypes, SignClientTypes } from '@walletconnect/types';
import { getSdkError } from '@walletconnect/utils';
import { take } from 'rxjs/operators';
import { SessionProposalModalComponent } from '../../../components/wallet-connect-modals/session-proposal-modal/session-proposal-modal.component';
import { WalletSignMessageModalComponent } from '../../../components/wallet-connect-modals/wallet-sign-message-modal/wallet-sign-message-modal.component';
import { WalletTransactionModalComponent } from '../../../components/wallet-connect-modals/wallet-transaction-modal/wallet-transaction-modal.component';
import { ArianeeService } from '../../arianee-service/arianee.service';
import { WalletConnectRequestHandlerService } from '../../wallet-connect-service/wallet-connect-request-handler/wallet-connect-requestHandler.service';
import WalletConnectV2ModalService from '../modal-service/modal.service';
import WalletConnectV2Utils, { SupportedChain } from '../utils';
import WalletConnectV2EventManager from './event-manager';

@Injectable({
  providedIn: 'root'
})
export default class WalletConnectV2SignEventManager extends WalletConnectV2EventManager<SignClientTypes.Event> {
  constructor (
    protected modalService: WalletConnectV2ModalService,
    protected requestHandler: WalletConnectRequestHandlerService,
    protected utils: WalletConnectV2Utils,
    private arianeeService: ArianeeService
  ) {
    super(modalService, requestHandler, utils);
  }

  public async handleEvent (
    event: SignClientTypes.Event,
    args: SignClientTypes.EventArguments[typeof event]
  ) {
    switch (event) {
      case 'session_proposal':
        await this.handleSessionProposalEvent(
          args as SignClientTypes.EventArguments['session_proposal']
        );
        break;
      case 'session_request':
        await this.handleSessionRequestEvent(
          args as SignClientTypes.EventArguments['session_request']
        );
        break;
      default:
        console.warn('[WCV2] Unhandled sign event', event, args);
        break;
    }
  }

  private async handleSessionProposalEvent (
    args: SignClientTypes.EventArguments['session_proposal']
  ) {
    const { approved } = await this.modalService.open(
      'session_proposal',
      SessionProposalModalComponent,
      args
    );

    const { requiredNamespaces, id, relays } = args.params;

    const client = await this.utils.getClient();

    if (approved) {
      const namespaces: SessionTypes.Namespaces = {};
      const address = await this.arianeeService.$address.pipe(take(1)).toPromise();

      Object.keys(requiredNamespaces).forEach((key) => {
        const accounts: string[] = [];
        requiredNamespaces[key].chains.forEach((chain) => {
          accounts.push(`${chain}:${address}`);
        });

        namespaces[key] = {
          accounts,
          methods: requiredNamespaces[key].methods,
          events: requiredNamespaces[key].events
        };
      });

      await client.approveSession({
        id,
        relayProtocol: relays[0].protocol,
        namespaces
      });
    } else {
      await client.rejectSession({
        id,
        reason: getSdkError('USER_REJECTED')
      });
    }
  }

  private async handleSessionRequestEvent (args: SignClientTypes.EventArguments['session_request']) {
    const { method } = args.params.request;
    switch (method) {
      case 'personal_sign':
      case 'eth_sign':
        await this.handleSignMessage(args);
        break;
      case 'eth_signTypedData':
      case 'eth_signTypedData_v3':
      case 'eth_signTypedData_v4':
        await this.handleSignTypedData(args);
        break;
      case 'eth_signTransaction':
        await this.handleSignTransaction(args);
        break;
      case 'eth_sendTransaction':
        await this.handleSendTransaction(args);
        break;
    }
  }

  private async handleSignMessage (args: SignClientTypes.EventArguments['session_request']) {
    const { approved } = await this.modalService.open(
      'session_request',
      WalletSignMessageModalComponent,
      args
    );

    const { topic } = args;
    const { chainId } = args.params;
    const client = await this.utils.getClient();

    let response: JsonRpcResponse;

    if (approved) {
      const message = this.utils.getSignParamsMessage(args);

      await this.prepareRequestHandler(chainId as SupportedChain);
      const signature = await this.requestHandler.signPersonalMessage(message);

      response = this.utils.approveEIP155Request(args, signature);
    } else {
      response = this.utils.rejectEIP155Request(args, getSdkError('USER_REJECTED'));
    }

    await client.respondSessionRequest({
      topic,
      response
    });
  }

  private async handleSignTypedData (args: SignClientTypes.EventArguments['session_request']) {
    const { approved } = await this.modalService.open(
      'session_request',
      WalletSignMessageModalComponent,
      args
    );

    const { topic } = args;
    const { chainId } = args.params;
    const client = await this.utils.getClient();

    let response: JsonRpcResponse;

    if (approved) {
      const { domain, types, message: data } = JSON.parse(this.utils.getSignParamsMessage(args));

      // https://github.com/ethers-io/ethers.js/issues/687#issuecomment-714069471
      delete types.EIP712Domain;

      await this.prepareRequestHandler(chainId as SupportedChain);

      const signature = await this.requestHandler._signTypedData(domain, types, data);

      response = this.utils.approveEIP155Request(args, signature);
    } else {
      response = this.utils.rejectEIP155Request(args, getSdkError('USER_REJECTED'));
    }

    await client.respondSessionRequest({
      topic,
      response
    });
  }

  private async handleSignTransaction (args: SignClientTypes.EventArguments['session_request']) {
    const { approved } = await this.modalService.open(
      'session_request',
      WalletTransactionModalComponent,
      args
    );

    const { topic } = args;
    const { chainId, request } = args.params;
    const client = await this.utils.getClient();

    let response: JsonRpcResponse;

    if (approved) {
      const transaction = request.params[0];

      await this.prepareRequestHandler(chainId as SupportedChain);
      const signature = await this.requestHandler._signTransaction(transaction);
      response = this.utils.approveEIP155Request(args, signature);
    } else {
      response = this.utils.rejectEIP155Request(args, getSdkError('USER_REJECTED'));
    }

    await client.respondSessionRequest({
      topic,
      response
    });
  }

  private async handleSendTransaction (args: SignClientTypes.EventArguments['session_request']) {
    const { approved } = await this.modalService.open(
      'session_request',
      WalletTransactionModalComponent,
      args
    );

    const { topic } = args;
    const { chainId, request } = args.params;
    const client = await this.utils.getClient();

    let response: JsonRpcResponse;

    if (approved) {
      const transaction = request.params[0];

      await this.prepareRequestHandler(chainId as SupportedChain);
      const { hash } = await this.requestHandler._sendTransaction(transaction);
      response = this.utils.approveEIP155Request(args, hash);
    } else {
      response = this.utils.rejectEIP155Request(args, getSdkError('USER_REJECTED'));
    }

    await client.respondSessionRequest({
      topic,
      response
    });
  }
}
