import { Component, Input, OnInit } from '@angular/core';

import { Icon } from '../icon/models/icon.model';

@Component({
	selector: 'app-action-button',
	templateUrl: './action-button.component.html',
	styleUrls: ['./action-button.component.scss'],
})
export class ActionButtonComponent implements OnInit {
	@Input()
	public icon: Icon;

	@Input()
	public title: string;

	@Input()
	public action: () => void;

	@Input()
	public disabled: boolean = false;

	@Input()
	public e2eTag: string = '';

	ngOnInit() {
		if (this.disabled) {
			this.icon = { ...this.icon, color: '#9799a3' };
		}
	}
}
