import {
	AfterViewChecked,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	QueryList,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NETWORK } from '@arianee/arianeejs';
import { CertificateSummary } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary';
import { EnrichedNotification } from '@arianeeprivate/wallet-shared-components';
import { IonContent, ModalController, NavController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { Icon } from '../../../../../components/redesign/icon/models/icon.model';
import { MediaItemCarousel } from '../../../../../components/redesign/media-carousel/models/media-item-carousel.model';
import { OptInOutModalComponent } from '../../../../../components/redesign/opt-in-out-modal/opt-in-out-modal.component';
import { ArianeeService } from '../../../../../providers/arianee-service/arianee.service';
import { EventLoggerService } from '../../../../../providers/event-logger/event-logger-service';
import { BrowserService } from '../../../../../providers/inapp-browser/inapp-browser-service';
import { NotificationService } from '../../../../../providers/notification-service/notification.service';
import { certificateSummaryMapper } from '../../../product-detail/utils/mappers/certificate-summary/certificate-summary-mapper';
import { getMediaCarouselItemFromCertificate } from '../../../product-detail/utils/mappers/media-carousel/media-carousel-mapper';
import { messageMapper } from '../../mappers/message-mapper';
import { MessageItem } from '../../models/message-item.model';

@Component({
	selector: 'app-chat-new',
	templateUrl: './chat-new.component.html',
	styleUrls: ['./chat-new.component.scss'],
})
export class ChatNewComponent implements OnInit, OnDestroy, AfterViewChecked {
	@ViewChild(IonContent, { read: IonContent, static: false })
	myContent: IonContent;

	@ViewChildren('messagesContainer') messagesContainer: QueryList<ElementRef>;
	messages: MessageItem[];
	certificate: CertificateSummary;
	network: NETWORK;
	messagesState: boolean[] = [];
	isFullscreen: boolean;
	currentMediaIndex: number;
	currentMessageMedias: MediaItemCarousel[] = [];
	action: {
		icon: Icon;
		action: () => void;
	};

	firstMedia: MediaItemCarousel;

	private alreadyScrolled = false;
	private certificateId: string;
	private destroy$: Subject<any> = new Subject<any>();

	constructor(
		private route: ActivatedRoute,
		private notificationService: NotificationService,
		private arianeeService: ArianeeService,
		private eventLogger: EventLoggerService,
		private navCtrl: NavController,
		private modalCtrl: ModalController,
		private browserService: BrowserService,
	) {
		window.onclick = this.interceptHref;
	}

	async ngOnInit() {
		this.certificateId = this.route.snapshot.paramMap.get('certificateId');
		this.network = this.route.snapshot.paramMap.get('network') as NETWORK;
		this.initMessages();
		this.action = {
			icon: {
				name: 'icon-nft-settings',
				borderRadius: '12px',
				width: '40px',
				height: '40px',
				fontSize: 24,
				fontWeight: 500,
				backgroundColor: 'transparent',
			},
			action: async () => await this.openOptInOutModal(),
		};
	}

	private async openOptInOutModal() {
		const mappedCertificate = await certificateSummaryMapper(this.certificate);
		const modal = await this.modalCtrl.create({
			component: OptInOutModalComponent,
			cssClass: 'modal--bottom',
			swipeToClose: true,
			componentProps: {
				data: {
					title: mappedCertificate.name,
					media: this.firstMedia ? this.firstMedia : null,
				},
				certificateId: this.certificateId,
				network: this.network,
			},
		});
		await modal.present();
	}

	async ngAfterViewChecked() {
		this.scrollToUnread();
	}

	private async scrollToUnread() {
		if (!this.alreadyScrolled) {
			const unreadElements = this.messagesContainer.filter((el) =>
				el.nativeElement.classList.contains('unread'),
			);
			if (unreadElements.length > 0) {
				const firstUnreadElement = unreadElements[0].nativeElement;
				await this.myContent.scrollToPoint(
					0,
					firstUnreadElement.offsetTop,
					100,
				);
			} else {
				await this.myContent.scrollToBottom(100);
			}
			this.alreadyScrolled = true;
		}
	}

	private markAsReadAllMessage(): void {
		this.notificationService
			.$notificationFilterByCertificateId(this.certificateId)
			.pipe(
				take(1),
				map((allMessages) => allMessages.map((message) => message.id)),
			)
			.subscribe((messageIds) => {
				messageIds.forEach((messageId) =>
					this.notificationService.markAsRead(this.certificateId, messageId),
				);
			});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	onClickBack() {
		this.markAsReadAllMessage();
		this.navCtrl.pop();
	}

	private initMessages() {
		this.arianeeService.getWalletInstance(this.network).then(async (wallet) => {
			await wallet.methods
				.getCertificate(this.certificateId, '', {
					messageSenders: true,
					issuer: true,
					content: true,
					owner: true,
				})
				.then(async (certificate) => {
					this.certificate = certificate;
					const mappedCertificate = await certificateSummaryMapper(
						this.certificate,
					);
					const certificateMedias =
						getMediaCarouselItemFromCertificate(mappedCertificate);
					this.firstMedia = certificateMedias[0] ? certificateMedias[0] : null;
					this.notificationService
						.$notificationFilterByCertificateId(this.certificateId)
						.pipe(takeUntil(this.destroy$))
						.subscribe((notifications: EnrichedNotification[]) => {
							this.eventLogger.logEvent('chat_displayedMessages', {
								certificateId: this.certificateId,
								messageIds: notifications.map((d) => d.id),
							});
							this.messages = messageMapper(notifications);
							this.messagesState = this.getMessagesState(this.messages);
						});
				});
		});
	}

	getMessagesState(messages) {
		let isFirstUnread = true;
		return messages.map((message) => {
			if (message.isUnread && isFirstUnread) {
				isFirstUnread = false;
				return true;
			} else {
				return false;
			}
		});
	}

	openFullscreen(payload: {
		isFullscreen: boolean;
		currentMediaIndex: number;
		messageId: string;
	}): void {
		this.isFullscreen = payload.isFullscreen;
		this.currentMediaIndex = payload.currentMediaIndex;
		this.currentMessageMedias = this.messages.find(
			(message) => message.id === payload.messageId,
		).content.medias;
	}

	onMediaClick(index: number): void {
		this.currentMediaIndex = index;
	}

	closeFullscreen(): void {
		this.isFullscreen = false;
	}

	private interceptHref = (event: MouseEvent) => {
		const target = event.target as HTMLElement;
		if (target.tagName === 'A') {
			event.preventDefault();
			const href = target.getAttribute('href');
			this.browserService.openBrowserSystem(href);
		}
	};
}
