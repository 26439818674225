import {Component, Input, OnInit} from '@angular/core';
import {ArianeeEvent} from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary/certificateSummary';
import {ModalController} from '@ionic/angular';

import {Button} from '../../../../../../../components/redesign/button/models/button.model';
import {Icon} from '../../../../../../../components/redesign/icon/models/icon.model';
import {BrowserService} from '../../../../../../../providers/inapp-browser/inapp-browser-service';
import {getYoutubeMedia} from '../../../../utils/youtube/youtube.helper';

@Component({
	selector: 'app-history-details-modal',
	templateUrl: './history-details-modal.component.html',
	styleUrls: ['./history-details-modal.component.scss'],
})
export class HistoryDetailsModalComponent implements OnInit {
	@Input() header: { title: string; icon?: Icon };
	@Input() arianeeEvent: ArianeeEvent;
	@Input() acceptEvent: (eventId: string) => Promise<boolean>;
	@Input() declineEvent: (eventId: string) => Promise<boolean>;
	@Input() isWeb: boolean;
	public modal: HTMLIonModalElement;

	formattedEvent: any;

	acceptButton: Button = {
		title: {
			text: 'Accept',
		},
		buttonType: 'primary',
		loadingText: 'Validating blockchain transaction',
		loading: false,
		disabled: false,
		actionFinished: false,
	};

	declineButton: Button = {
		title: {
			text: 'Decline',
			color: 'black',
		},
		loadingText: 'Validating blockchain transaction',
		loading: false,
		disabled: false,
		actionFinished: false,
	};

	constructor(
		private modalCtrl: ModalController,
		private browserService: BrowserService,
	) {
		document.onclick = this.interceptHref;
	}

	async ngOnInit() {
		this.initFormattedEvent();
		this.modal = await this.modalCtrl.getTop();
	}

	private initFormattedEvent() {
		this.formattedEvent = {
			description: this.arianeeEvent.content.data.description,
			isPending: this.arianeeEvent.pending,
			externalContents: this.arianeeEvent.content.data.externalContents
				? this.arianeeEvent.content.data.externalContents.map((link) => ({
						icon: {
							name: 'icon-link',
							fontSize: 24,
						},
						title: link.title,
						actionIcon: 'link',
						action: () => this.browserService.openBrowser(link.url),
				  }))
				: [],
			medias: this.arianeeEvent.content.data.medias
				? this.arianeeEvent.content.data.medias.map((media) => {
						if (media.mediaType === 'youtube') {
							media = getYoutubeMedia(media);
						}
						return media;
				  })
				: [],
		};
	}

	onAcceptEvent() {
		this.acceptButton = {
			...this.acceptButton,
			loading: true,
		};

		this.declineButton = {
			...this.declineButton,
			disabled: true,
		};

		this.acceptEvent(this.arianeeEvent.arianeeEventId)
			.then((loading) => {
				this.acceptButton = {
					loadingText: '',
					loading: loading,
					actionFinished: true,
					disabled: false,
					title: {
						text: 'Accepted',
						color: '#34C759',
					},
					customStyle: {
						'background-color': '#D3F3DB',
					},
				};
			})
			.catch((loading) => {
				this.acceptButton = {
					...this.acceptButton,
					loading: loading,
					disabled: false,
				};

				this.declineButton = {
					...this.declineButton,
					disabled: false,
				};
			});
	}

	onDeclineEvent() {
		this.declineButton = {
			...this.declineButton,
			loading: true,
		};

		this.acceptButton = {
			...this.acceptButton,
			disabled: true,
		};

		this.declineEvent(this.arianeeEvent.arianeeEventId)
			.then((loading) => {
				this.declineButton = {
					...this.declineButton,
					loading: loading,
					actionFinished: true,
					disabled: false,
					title: {
						text: 'Declined',
						color: '#FF2C55',
					},
					customStyle: {
						'background-color': '#FFDCD4',
					},
				};
			})
			.catch((loading) => {
				this.declineButton = {
					...this.declineButton,
					loading: loading,
					disabled: false,
				};

				this.acceptButton = {
					...this.acceptButton,
					disabled: false,
				};
			});
	}

	onCloseModal(): void {
		this.modalCtrl.dismiss({
			dismissed: true,
		});
	}

	private interceptHref = (event: MouseEvent) => {
		const target = event.target as HTMLElement;
		if (target.tagName === 'A') {
			event.preventDefault();
			const href = target.getAttribute('href');
			this.browserService.openBrowserSystem(href);
		}
	};
}
