import { hubspotForms } from '../../app/pages/main/setting/modals/contact/config/hubspot-forms';

export const defaultCommonEnvironment = {
  appDisplayName: 'Arianee',
  secureStorageKey: 'ArianeeKeyStore',
  demoPassportEndpoint: 'https://api.bdh-demo.arianee.com/certificate/demo',
  authorizedIssuers: [],
  transactionOptions: {
    gas: 400000,
    gasPrice: 10000000000
  },
  pgeneveUrl: 'https://www.poincondegeneve.ch/secure?',
  swissmadeUrl: 'https://www.poincondegeneve.ch/secure?',
  blockchainProvider: {
    mainnet: 'https://poa.arianee.net',
    testnet: 'https://sokol.arianee.net'
  },
  arianeeApi: {
    url: 'https://api.arianee.org'
  },
  arianeeCustodial: {
    url: 'https://custodial.arianee.com/v1/:network'
  },
  walletApi: {
    url: 'https://us-central1-arianeewallet-80f63.cloudfunctions.net'
  },
  poap: {
    url: 'https://poap.arianee.workers.dev'
  },
  proxyAuthorizedDomain: 'https://share.arianee.net/proxifier/authorized.json',
  countryUrl: 'https://country.arianee.net',
  hasOnboarding: true,
  arianeeWebsite: 'https://arianee.org',
  arianeeContact: 'contact@arianee.org',
  arianeeTwitter: 'arianeeProject',
  privacyPoliciesLink: {
    default: 'https://www.arianee.org/privacy-policies',
    'zh-cn': 'https://www.arianee.org/privacy-policies-chinese',
    'zh-tw': 'https://www.arianee.org/privacy-policies-chinese'
  },
  userAgreementsLink: {
    default: 'https://www.arianee.org/user-agreement',
    'zh-cn': 'https://www.arianee.org/user-agreement-chinese',
    'zh-tw': 'https://www.arianee.org/user-agreement-chinese'
  },
  slack: {
    apikey: 'BIERv0GfaAWFRgkOBCxoanwD'
  },
  walletVersionUrl: 'https://share.arianee.net/walletVersion.json',
  // defined here https://console.cloud.google.com/apis/credentials?project=arianeewallet-80f63
  webIdGoogleDrive: '227772393458-82mnlca6c9m7bgm6vqqroccltgj7j0dt.apps.googleusercontent.com',
  appURL: {
    appleStore: 'https://apps.apple.com/app/arianee-wallet/id1435782507',
    playStore: 'https://play.google.com/store/apps/details?id=com.arianee.wallet'
  },
  sentry: {
    dsn: 'https://06cb016c0e9e465d82c9ae101dad429e@o343653.ingest.sentry.io/1889602'
  },
  backupSecret: '6d3c3bcea566e48bc871eb3b31df6acd849a76351b968180519f2902161c4941',
  passphraseSecret: '0xe5f85126b104ead5abe7fdc1859bbde9b26e694504926ff98118f2f20a72befa',
  baiduAPI: { apiKey: '' },
  batchAPI: {
    androidAPIKey: '5B437D0E4A0B22C3B89C82F2F6BF50',
    iOSAPIKey: '5B437C6388114A2A4EBA67FAA0698B'
  },
  exchange: {
    url: 'https://exchange-platform-frontend-prod-e5vjxsajla-ew.a.run.app'
  },
  blockchainProxy: {
    enable: true,
    host: 'https://api.arianee.com/report',
    base: 'https://api.arianee.com'
  },
  arianeeInstagram: null,
  jwtAuthUrl: 'https://auth.arianee.net',
  canRequestFaucetUrl: 'https://faucet2.arianee.net/faucet/canRequest',
  hubspotForms: hubspotForms,
  walletConnectV2: {
    projectId: 'f0e06e319b7c24a043f88a70fb7d5df6',
    metadata: {
      name: 'Arianee .Wallet',
      description: 'Arianee .Wallet',
      url: 'https://arianee.org/',
      icons: ['https://api.bdh-demo-polygon.arianee.com/pub/1672850229667-Logo-Arianee-.A-1.png']
    }
  }

};
