import { unionFeatureFlip } from './helpers/unionEnvironment';

export const defaultDEVFeatureFlip = [
	{
		name: 'resell',
		activated: true,
		description: 'resell feature',
	},
	{
		name: 'callFaucetOnLaunch',
		activated: true,
		description: 'request poa faucet on call',
	},
	{
		name: 'blockChainWatcherEventOff',
		activated: false,
		description: 'turn OFF blochain event',
	},
	{
		name: 'deletePassport',
		activated: true,
		description: 'delete a passport',
	},
	{
		name: 'brandsList',
		activated: false,
		description: 'add brand list in tab',
	},
	{
		name: 'hideNetworkBanner',
		activated: false,
		description: 'always hide network banner',
	},
	{
		name: 'lost',
		activated: false,
		description: 'lost or stolen feature',
	},
	{
		name: 'exchange',
		activated: true,
		description: 'exchange',
	},
	{
		name: 'newProductDetail',
		activated: true,
		description: 'Redesign product detail',
	},
	{
		name: 'newCollectionsList',
		activated: true,
		description: 'Redesign collections list',
	},
	{
		name: 'newNotificationsPage',
		activated: true,
		description: 'Redesign notifications',
	},
	{
		name: 'landingClaimWithAddress',
		activated: false,
		description: 'Show claim with address form on the landing page',
	},
	{
		name: 'newSettingsPage',
		activated: false,
		description: 'Redesign settings page',
	},
	{
		name: 'darkMode',
		activated: false,
		description: 'Enable dark mode theme',
	},
];
export const featureFlipCommonDefautDEV = unionFeatureFlip(
	defaultDEVFeatureFlip,
);
