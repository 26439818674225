import { TitleCasePipe } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NETWORK } from '@arianee/arianeejs';
import { ArianeeWallet } from '@arianee/arianeejs/dist/src/core/wallet';
import { CertificateSummary } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary';
import {
	ArianeeEvent,
	CertificateEvents,
} from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary/certificateSummary';
import { IdentityBase } from '@arianee/arianeejs/dist/src/models';
import { ExtendedBoolean } from '@arianee/arianeejs/dist/src/models/extendedBoolean';
import {
	POAPNFT,
	ToasterService,
} from '@arianeeprivate/wallet-shared-components';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subject, timer } from 'rxjs';
import {
	filter,
	first,
	map,
	mergeMap,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';

import { Icon } from '../../../../../components/redesign/icon/models/icon.model';
import { ListItem } from '../../../../../components/redesign/list/models/list-item.model';
import { MediaItemCarousel } from '../../../../../components/redesign/media-carousel/models/media-item-carousel.model';
import {
	NftStatus,
	NftStatusType,
} from '../../../../../components/redesign/nft-status/models/nft-status.model';
import { OnBoardingModalComponent } from '../../../../../components/redesign/on-boarding/on-boarding-modal.component';
import { TextAlignment } from '../../../../../components/redesign/title/models/text-alignment.enum';
import {
	createCertificateProofLink,
	createCertificateRequestOwnershipLink,
} from '../../../../../helpers/wallet-methods';
import { ArianeeBlockchainProxyService } from '../../../../../providers/arianee-blockchain-proxy-service/arianee-blockchain-proxy-service';
import { ArianeeService } from '../../../../../providers/arianee-service/arianee.service';
import { ArianeeEventService } from '../../../../../providers/arianeeEvent-service/arianeeEvent.service';
import { BackupCheckService } from '../../../../../providers/backup-check-service/backup-check.service';
import { EventLoggerService } from '../../../../../providers/event-logger/event-logger-service';
import { BrowserService } from '../../../../../providers/inapp-browser/inapp-browser-service';
import { NotificationService } from '../../../../../providers/notification-service/notification.service';
import { PendingNftService } from '../../../../../providers/pending-nft-service/pending-nft.service';
import {
	PoapClaimResponse,
	PoapQueueStatusResponse,
	PoapService,
} from '../../../../../providers/poap/poap.service';
import { UserService } from '../../../../../providers/user-service/user.service';
import { getChainTypeFromNetwork } from '../../../../../providers/wallet-connect-service/chain-utils/chain-utils';
import { ChainType } from '../../../../../types/multichain';
import { AuthPage } from '../../../../auth/auth.page';
import { poapCertificateSummaryMapper } from '../../../poap-detail/mapper/poap-certificate-summary-mapper';
import { getMediaCarouselItemFromPoapCertificate } from '../../../poap-detail/mapper/poap-media-carousel-mapper';
import { BrandAuthenticityModalComponent } from '../../modals/brand-authenticity-modal/brand-authenticity-modal.component';
import { BrandModalComponent } from '../../modals/brand-modal/brand-modal.component';
import { ClaimCongratsModalComponent } from '../../modals/claim-congrats-modal/claim-congrats-modal.component';
import { HistoryModalComponent } from '../../modals/history-modal/history-modal.component';
import { TransferAddressModalComponent } from '../../modals/transfer-address-modal/transfer-address-modal.component';
import { TransferChoiceModalComponent } from '../../modals/transfer-choice-modal/transfer-choice-modal.component';
import { TransferProofModalComponent } from '../../modals/transfer-proof-modal/transfer-proof-modal.component';
import { TransparencyModalComponent } from '../../modals/transparency-modal/transparency-modal.component';
import { MappedCertificate } from '../../models/mapped-certificate.model';
import { dateFormatter } from '../../utils/date/date-formatter';
import { ExternalContentService } from '../../utils/externalContentsHandler';
import { certificateSummaryMapper } from '../../utils/mappers/certificate-summary/certificate-summary-mapper';
import { arianeeEventsMapper } from '../../utils/mappers/events/arianee-events-mapper';
import { poapEventsMapper } from '../../utils/mappers/events/poap-events-mapper';
import { transferEventsMapper } from '../../utils/mappers/events/transfer-events-mapper';
import {
	mapExternalContentsToLinksListItems,
	mapSocialMediasToListItems,
	mapTransparencyItemsToListItems,
} from '../../utils/mappers/list-items/list-items-mappers';
import { getMediaCarouselItemFromCertificate } from '../../utils/mappers/media-carousel/media-carousel-mapper';

@Component({
	selector: 'app-product-detail-new',
	templateUrl: './product-detail-new.component.html',
	styleUrls: ['./product-detail-new.component.scss'],
})
export class ProductDetailNewComponent
	implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
	@Input() arianeeCertificate: CertificateSummary;
	@Input() poapCertificate: { poapNft: POAPNFT; identity: IdentityBase };
	@Input() walletAddress: string;
	@Input() isWeb: boolean;
	@Input() proof: ExtendedBoolean;
	@Input() certificateEvents: CertificateEvents;
	@Input() isClaimed: boolean = false;
	@Output() onPendingNftClaim: EventEmitter<boolean> =
		new EventEmitter<boolean>();

	@Output() refreshNFT: EventEmitter<void> = new EventEmitter<void>();
	@Output() onDownloadAppClick: EventEmitter<
		'appStoreButton' | 'googlePlayButton' | 'ctaButton'
	> = new EventEmitter();

	@ViewChild('header', {
		read: ElementRef,
		static: false,
	})
	header: ElementRef<HTMLElement>;

	@ViewChild('claimSlider', {
		read: ElementRef,
		static: false,
	})
	claimSlider: ElementRef<HTMLElement>;

	@ViewChild('landingDetailComponent', {
		read: ElementRef,
		static: false,
	})
	landingDetailComponent: ElementRef<HTMLElement>;

	textAlignment = TextAlignment;
	actionsList: { title: string; itemsList: ListItem[] }[] = [];
	carouselItems: MediaItemCarousel[] = [];
	isFullscreen: boolean = false;
	currentMediaIndex: number = 0;
	historyItems: ListItem[] = [];
	certificate: MappedCertificate;
	showButtonsContainer: boolean = true;
	buttonsList = [];
	itemBackgroundPicture: string;
	claimLoading: { label: string; isLoading: boolean };
	authenticityIcon: Icon;
	poapData: {
		poapTokenId: string;
		poapQRHash: string;
		poapNFTSecret: string;
	};

	propertiesItems: ListItem[] = [];
	nftStatus: NftStatus;

	displayClaimSliderContainer: boolean = false;
	displayNftStatus: boolean = false;
	displayClaimSlider: boolean = false;
	isAndroid: boolean = false;

	private destroy$: Subject<void> = new Subject<void>();
	private wallet: ArianeeWallet;
	private mergedEvents: ArianeeEvent[] = [];
	private method: string;

	private passphrase: string;
	private network: NETWORK;

	public headerHeight: number = 0;
	public footerHeight: number = 0;

	constructor(
		private clipBoard: Clipboard,
		private navCtrl: Router,
		private toasterService: ToasterService,
		private notificationService: NotificationService,
		private browserService: BrowserService,
		private arianeeEventService: ArianeeEventService,
		private modalCtrl: ModalController,
		private arianeeService: ArianeeService,
		private activatedRoute: ActivatedRoute,
		private eventLogger: EventLoggerService,
		private poapService: PoapService,
		private translate: TranslateService,
		private arianeeBlockchainProxyService: ArianeeBlockchainProxyService,
		private userService: UserService,
		private pendingNftService: PendingNftService,
		private cdr: ChangeDetectorRef,
		private platform: Platform,
		private externalContentService: ExternalContentService,
		private backupCheckService: BackupCheckService,
	) {}

	get $getUnreadNotifications() {
		return this.notificationService.$getUnreadNotification().pipe(
			filter(() => this.certificate !== undefined && this.certificate !== null),
			map((notifications) =>
				notifications.filter(
					(notification) => notification.tokenId === this.certificate.id,
				),
			),
			map((notifications) => notifications.length),
		);
	}

	async ngOnInit() {
		this.isAndroid = this.platform.is('android');
		this.getCertificateData();
		await this.setWalletInstanceFromRoute();
		await this.onBoardingCheck();
	}

	ngAfterViewInit() {
		this.setHeaderHeight();
		this.setLandingDetailHeight();
	}

	private setLandingDetailHeight() {
		if (
			!this.isWeb ||
			(this.isWeb && this.displayNftStatus) ||
			window.innerWidth > 1024
		) {
			return;
		}

		if (!this.landingDetailComponent) {
			setTimeout(this.setLandingDetailHeight.bind(this), 100);
			return;
		}

		const sab = getComputedStyle(document.documentElement)
			.getPropertyValue('--sab')
			.trim();

		const sabValue = parseInt(sab.replace('px', ''));

		const childArray = Array.from(
			this.landingDetailComponent.nativeElement.childNodes.values(),
		);

		const footerElement = childArray.find(
			(node) => node.nodeName === 'ION-FOOTER',
		);

		this.footerHeight =
			(footerElement as HTMLElement).offsetHeight + // slider height
			sabValue + // safe area bottom
			16; // claim-slider padding bottom+top
	}

	private setSliderHeight() {
		if (!this.displayClaimSliderContainer) {
			return;
		}
		if (!this.claimSlider) {
			setTimeout(this.setSliderHeight.bind(this), 100);
			return;
		}
		const sab = getComputedStyle(document.documentElement)
			.getPropertyValue('--sab')
			.trim();

		const sabValue = parseInt(sab.replace('px', ''));
		this.footerHeight =
			(this.claimSlider.nativeElement.firstElementChild as HTMLElement)
				.offsetHeight + // slider height
			sabValue + // safe area bottom
			16; // claim-slider padding bottom+top
	}

	private setHeaderHeight() {
		if (!this.header) {
			setTimeout(this.ngAfterViewInit.bind(this), 10);
			return;
		}

		this.headerHeight = (
			this.header.nativeElement.firstElementChild as HTMLElement
		).offsetHeight;

		// Retry if css not yet loaded
		if (this.headerHeight === 0)
			setTimeout(this.ngAfterViewInit.bind(this), 10);
	}

	private getCertificateData() {
		this.method = this.activatedRoute.snapshot.paramMap.get('method');
		this.passphrase = this.activatedRoute.snapshot.paramMap.get('passphrase');

		if (this.poapCertificate) {
			this.poapData = {
				poapTokenId: this.activatedRoute.snapshot.paramMap.get('tokenId'),
				poapQRHash: this.activatedRoute.snapshot.paramMap.get('qrHash'),
				poapNFTSecret: this.poapCertificate.poapNft.secret,
			};
		}
	}

	async onBoardingCheck(): Promise<void> {
		this.userService
			.hasOnboarded()
			.pipe(takeUntil(this.destroy$))
			.subscribe(async (hasOnboarded) => {
				if (hasOnboarded) return;
				if (this.method !== 'requestOwnership') return;

				this.poapService.getMyPoapNfts();

				const [mainnetCertificates, testnetCertificates, poapCertificates] =
					await Promise.all([
						this.arianeeBlockchainProxyService.getCertificates(
							ChainType.mainnet,
							this.wallet.address,
						),
						this.arianeeBlockchainProxyService.getCertificates(
							ChainType.testnet,
							this.wallet.address,
						),
						this.poapService.$poapList.pipe(take(1)).toPromise(),
					]);

				const totalCertificatesCount =
					mainnetCertificates.length +
					testnetCertificates.length +
					poapCertificates.length;

				if (!this.isWeb && totalCertificatesCount === 0) {
					await this.openOnBoardingModal();
				}

				this.userService.setOnBoardingStatus();
			});
	}

	private initButtonsList() {
		this.buttonsList = [
			{
				id: 'notifications',
				title: this.translate.instant('ProductDetails.notifications'),
				icon: {
					name: 'icon-chat',
					fontSize: 28,
					color: 'var(--neutral-1000)',
					backgroundColor: 'var(--neutral-100)',
				},
				action: () =>
					this.navCtrl.navigate(
						[
							`/tab/brand-list/product-detail/notification/${this.network}/${this.certificate.id}`,
						],
						{ skipLocationChange: true },
					),
				disabled: this.arianeeCertificate === undefined,
				e2eTag: 'message-btn',
			},
			{
				title: this.translate.instant('ProductDetails.prove'),
				icon: {
					name: 'icon-owner',
					fontSize: 28,
					color: 'var(--neutral-1000)',
					backgroundColor: 'var(--neutral-100)',
				},
				action: () => this.openTransferProofModal('proof'),
				disabled: this.arianeeCertificate === undefined,
				e2eTag: 'share-btn',
			},
			{
				title: this.translate.instant('ProductDetails.transfer'),
				icon: {
					name: 'icon-transfer-ownership',
					fontSize: 28,
					color: 'var(--neutral-1000)',
					backgroundColor: 'var(--neutral-100)',
				},
				action: () => this.openTransferChoiceModal('transfer'),
				disabled: this.arianeeCertificate === undefined,
				e2eTag: 'transfer-btn',
			},
			{
				title: this.translate.instant('ProductDetails.enquirus'),
				icon: {
					name: 'far fa-eye',
					color: 'var(--neutral-1000)',
					backgroundColor: 'var(--neutral-100)',
				},
				action: () => alert('list item clicked'),
				disabled: true,
			},
		];

		this.$getUnreadNotifications
			.pipe(takeUntil(this.destroy$))
			.subscribe((unreadNotifications) => {
				const notificationsButton = this.buttonsList.find(
					(button) => button.id === 'notifications',
				);
				if (unreadNotifications > 0 && notificationsButton) {
					notificationsButton.icon.name = 'icon-unread';
				}
			});

		this.showButtonsContainer = !this.buttonsList.every(
			(button) => button.disabled,
		);
	}

	async ngOnChanges(changes: SimpleChanges) {
		if (changes) {
			await this.handleCertificates();
			this.getCertificateData();
			this.initButtonsList();
			this.initListItems();
			this.initFooter();
			this.initProperties();
		}
	}

	private initProperties() {
		this.propertiesItems = [];
		if (this.certificate && this.certificate.serialNumber) {
			this.certificate.serialNumber.filter((item) => {
				const titleMap = {
					serialnumber: 'CertificateInfos.serialNumber',
					casenumber: 'CertificateInfos.caseNumber',
					millesimation: 'CertificateInfos.millesimation',
					vin: 'CertificateInfos.vin',
					movementnumber: 'CertificateInfos.movementNumber',
				};
				const title = titleMap[item.type];
				if (title) {
					this.propertiesItems = [
						...this.propertiesItems,
						{
							title: this.translate.instant(title),
							icon: undefined,
							subtitle: item.value ? item.value : undefined,
							actionIcon: 'none',
						},
					];
				}
			});
		}
	}

	private getItemBackgroundPictureUrl(): string {
		let itemBackgroundPictureUrl = '';

		if (this.certificate.medias) {
			const itemBackgroundPictureMedia = this.certificate.medias.find(
				(media) => media.type === 'itemBackgroundPicture',
			);
			if (itemBackgroundPictureMedia) {
				itemBackgroundPictureUrl = itemBackgroundPictureMedia.url;
			} else if (this.certificate.identity.itemBackgroundPicture) {
				itemBackgroundPictureUrl =
					this.certificate.identity.itemBackgroundPicture;
			}

			return itemBackgroundPictureUrl;
		}
	}

	private async handleCertificates() {
		if (this.arianeeCertificate) {
			this.certificate = await certificateSummaryMapper(
				this.arianeeCertificate,
			);
			this.itemBackgroundPicture = this.getItemBackgroundPictureUrl();
			this.carouselItems = getMediaCarouselItemFromCertificate(
				this.certificate,
			);
			this.initHistoryItems();
		} else if (this.poapCertificate) {
			this.certificate = poapCertificateSummaryMapper(this.poapCertificate);
			this.certificate.isOwner = this.getPoapOwner();
			this.carouselItems = getMediaCarouselItemFromPoapCertificate(
				this.certificate,
			);
			this.historyItems = poapEventsMapper(this.certificate, this.translate);
		}
		this.setAuthenticityIcon();
	}

	private getPoapOwner() {
		const ownerOrBeneficiary =
			this.poapCertificate.poapNft.beneficiary ||
			this.poapCertificate.poapNft.owner;
		return ownerOrBeneficiary
			? ownerOrBeneficiary.toLowerCase() === this.walletAddress.toLowerCase()
			: false;
	}

	private setAuthenticityIcon() {
		if (!this.certificate) {
			return;
		}
		let areAllValid: boolean = this.certificate.isContentAuthentic;
		if (this.certificate.identity) {
			const { isIdentityAuthentic, isIdentityVerified } =
				this.certificate.identity;
			areAllValid = areAllValid && isIdentityAuthentic && isIdentityVerified;
		}

		this.authenticityIcon = {
			image: {
				url: `/assets/imgs/icons/${
					areAllValid ? 'arianee_blue_400' : 'arianee_red_400'
				}.png`,
				width: '40px',
				height: '40px',
			},
			backgroundColor: 'transparent',
		};
	}

	private initHistoryItems(): void {
		const mergedEvents: ArianeeEvent[] = this.mergeAndTransformEvents(
			this.certificateEvents,
		);
		this.mergedEvents = mergedEvents;
		this.historyItems = this.filterAndSortEvents(mergedEvents);
	}

	private mergeAndTransformEvents(events: CertificateEvents): ArianeeEvent[] {
		const mergedEvents = this.mergeEvents(events);
		return mergedEvents.map((event) => {
			if (event.event === 'Transfer') {
				return this.transformTransferEvent(event);
			} else {
				return this.transformArianeeEvent(event);
			}
		});
	}

	private transformArianeeEvent(event: ArianeeEvent): ArianeeEvent {
		const title = event.content.data.title;
		const eventType = event.content.data.eventType;

		if (title) {
			event.content.data.title = title;
			event.content.data.highlightText = 'none';
		} else if (eventType) {
			event.content.data.title = TitleCasePipe.prototype.transform(
				this.translate.instant(`History.${event.content.data.eventType}`),
			);
		} else {
			event.content.data.title = this.translate.instant('History.eventBy');
		}

		return event;
	}

	private transformTransferEvent(event): ArianeeEvent {
		const titleKey = 'CertificateEvents.receivedBy';
		event.title = this.translate.instant(titleKey);

		const walletAddress = this.walletAddress;
		const identity = event.identity;
		const identityAddress = identity.address;
		const isRecipientSelf = walletAddress === identityAddress;

		const identityName = identity.data ? identity.data.name : null;
		const highlightTextKey = isRecipientSelf
			? 'CertificateEvents.you'
			: identityName || 'CertificateEvents.someoneElse';

		event.highlightText = this.translate.instant(highlightTextKey);

		return event;
	}

	private filterAndSortEvents(events): ListItem[] {
		const transferEvents = events.filter((event) => event.event === 'Transfer');
		const filteredEvents = [
			...transferEventsMapper(this.certificate, transferEvents),
			...arianeeEventsMapper(
				this.certificate,
				events.filter((event) => event.event !== 'Transfer'),
			),
		];
		return filteredEvents.sort(
			(a, b) => new Date(b.subtitle).getTime() - new Date(a.subtitle).getTime(),
		);
	}

	private initListItems(): void {
		const pendingEventsLength = this.mergedEvents.filter(
			(event) => event.pending === true,
		);
		this.actionsList = [
			{
				title: this.translate.instant('ProductDetails.utilities'),
				itemsList:
					this.certificate &&
					mapExternalContentsToLinksListItems(
						this.certificate.externalContents,
						this.browserService,
						{
							isOwner: this.certificate.isOwner,
							isPending: this.isPendingCertificate(this.arianeeCertificate),
							certificateId: this.certificate.id,
							network: this.network,
							claimable: this.certificate.isRequestable,
						},
						this.externalContentService,
					),
			},
			{
				title:
					this.certificate && !this.certificate.isPassport
						? this.translate.instant('ProductDetails.nftDetails')
						: this.translate.instant('ProductDetails.productPassport'),
				itemsList: [
					{
						title: this.translate.instant('ProductDetails.history'),
						actionIcon: 'link',
						icon: {
							name: 'icon-history',
							fontSize: 24,
						},
						action: () => this.openHistoryModal(),
						badge: {
							count: pendingEventsLength.length,
							enableBadge: pendingEventsLength.length > 0,
						},
						e2eTag: 'history-btn',
					},
					...(this.certificate && this.certificate.transparencyItems.length > 0
						? ([
								{
									title: this.translate.instant('ProductDetails.transparency'),
									actionIcon: 'link',
									icon: {
										name: 'icon-transparency',
										fontSize: 24,
									},
									action: this.openTransparencyModal.bind(this),
									e2eTag: 'transparency-btn',
								},
						  ] as ListItem[])
						: []),
					...(this.arianeeCertificate
						? ([
								{
									title: this.translate.instant(
										'ProductDetails.brandCertification',
									),
									actionIcon: 'link',
									icon: {
										...this.authenticityIcon,
										fontSize: 37,
									},
									action: this.openBrandAuthenticityModal.bind(this),
									e2eTag: 'protocol-certification',
								},
						  ] as ListItem[])
						: []),
					{
						title: this.translate.instant('ProductDetails.about'),
						highlightText: this.certificate
							? this.certificate.identity.brandName
							: '',
						actionIcon: 'link',
						icon: {
							height: '40px',
							width: '40px',
							border: '1px solid rgba(0, 0, 0, 0.06)',
							borderRadius: '50%',
							image: this.certificate
								? {
										url: this.certificate.identity.brandLogoSquare,
										height: '100%',
										width: '100%',
								  }
								: null,
						},
						action: this.openBrandModal.bind(this),
					},
				],
			},
			this.certificate &&
			this.certificate.isOwner &&
			!this.certificate.isRequestable
				? {
						title: this.translate.instant('ProductDetails.walletInfos'),
						itemsList: [
							{
								title: this.translate.instant('ProductDetails.wallet'),
								subtitle: this.walletAddress
									? this.walletAddress.substring(0, 4) +
									  '...' +
									  this.walletAddress.substring(
											this.walletAddress.length - 4,
											this.walletAddress.length,
									  )
									: '',
								actionIcon: 'copy',
								icon: {
									name: 'icon-ethereum',
									fontSize: 24,
								},
								action: async () => {
									await this.clipBoard.copy(this.walletAddress);
									this.toasterService.showRedesigned({
										title: 'Settings.copyToClipboard',
										color: 'info',
										icon: 'information',
										position: 'top',
										duration: 2000,
									});
								},
								original: this.walletAddress,
							},
						],
				  }
				: null,
		];
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	openFullscreen(payload: {
		isFullscreen: boolean;
		currentMediaIndex: number;
	}): void {
		this.isFullscreen = payload.isFullscreen;
		this.currentMediaIndex = payload.currentMediaIndex;
		this.cdr.detectChanges();
	}

	closeFullscreen(index: number): void {
		this.isFullscreen = false;
		this.currentMediaIndex = index;
		this.cdr.detectChanges();
	}

	onMediaClick(index: number): void {
		this.currentMediaIndex = index;
	}

	private mergeEvents(events: CertificateEvents) {
		const transfer: any[] = Array.from(events ? events.transfer : []);

		if (transfer.length > 0) {
			let issuerFound = false;
			for (let i = 0; i < transfer.length; i++) {
				if (!issuerFound) {
					if (
						transfer[i].returnValues._to === this.certificate.identity.address
					) {
						issuerFound = true;
					}

					transfer.shift();
				}
			}
		}

		if (events.arianeeEvents.length > 0 || events.transfer.length > 0) {
			const history: any[] = (
				Array.from(events.arianeeEvents) as ArianeeEvent[]
			)
				// Don't display if no content
				.filter((arianeeEvent) => arianeeEvent.content)
				// If arianeeEvent is pending, user should be owner to see it
				.filter(
					(arianeeEvent) => !arianeeEvent.pending || this.certificate.isOwner,
				)
				.concat(transfer);
			history.sort(ProductDetailNewComponent.sortEventsDescending);
			return history;
		} else {
			return [];
		}
	}

	private static sortEventsDescending(a, b): number {
		if (a.timestamp > b.timestamp) {
			return -1;
		} else if (a.timestamp < b.timestamp) {
			return 1;
		}
		return 0;
	}

	private async openHistoryModal() {
		await this.openModal(HistoryModalComponent, {
			historyItems: this.historyItems,
			header: {
				title: 'History',
				icon: {
					name: 'icon-history',
					fontSize: 42,
					height: '72px',
					width: '72px',
					borderRadius: '16px',
				},
			},
			isWeb: this.isWeb,
			acceptEvent: this.acceptEvent,
			declineEvent: this.declineEvent,
		});
	}

	private acceptEvent = async (eventId: string): Promise<boolean> => {
		try {
			await this.wallet.methods.acceptArianeeEvent(eventId);
			await this.arianeeEventService.refreshEvents(
				this.wallet,
				this.arianeeCertificate.certificateId,
			);
			this.certificateEvents = await this.arianeeEventService
				.getCachedEvents(this.arianeeCertificate.certificateId)
				.pipe(take(1))
				.toPromise();
			return false;
		} catch (e) {
			console.error(e);
			return false;
		}
	};

	private declineEvent = async (eventId: string): Promise<boolean> => {
		try {
			await this.wallet.methods.refuseArianeeEvent(eventId);
			await this.arianeeEventService.refreshEvents(
				this.wallet,
				this.arianeeCertificate.certificateId,
			);
			this.certificateEvents = await this.arianeeEventService
				.getCachedEvents(this.arianeeCertificate.certificateId)
				.pipe(take(1))
				.toPromise();
			return false;
		} catch (e) {
			console.error(e);
			return false;
		}
	};

	private async setWalletInstanceFromRoute() {
		this.network = this.activatedRoute.snapshot.paramMap.get(
			'network',
		) as NETWORK;
		this.wallet = await this.arianeeService.getWalletInstance(
			this.network || NETWORK.mainnet,
		);
	}

	async openBrandModal() {
		if (!this.certificate || !this.certificate.identity) return;
		const { brandName, brandLogoSquare, brandDescription } =
			this.certificate.identity;

		const externalContentsListItems = mapExternalContentsToLinksListItems(
			this.certificate.identity.externalContents,
			this.browserService,
			{
				isOwner: this.certificate.isOwner,
				isPending: this.isPendingCertificate(this.arianeeCertificate),
				certificateId: this.certificate.id,
				network: this.network,
				claimable: this.certificate.isRequestable,
			},
			this.externalContentService,
		);
		const socialMediasListItems = mapSocialMediasToListItems(
			this.certificate.identity.socialMedias,
			this.browserService,
		);

		await this.openModal(BrandModalComponent, {
			externalContents: externalContentsListItems,
			socialNetworks: socialMediasListItems,
			content: brandDescription,
			isWeb: this.isWeb,
			header: {
				title: brandName,
				icon: {
					image: {
						url: brandLogoSquare,
						width: '72px',
						height: '72px',
					},
					border: '1px solid rgba(0, 0, 0, 0.06)',
					height: '72px',
					width: '72px',
					borderRadius: '16px',
				},
			},
		});
	}

	async openBrandAuthenticityModal() {
		if (!this.certificate) return;

		const { identity } = this.certificate;
		const identityProp = identity
			? {
					brandName: identity.brandName,
					isAuthentic: identity.isIdentityAuthentic,
					isVerified: identity.isIdentityVerified,
			  }
			: null;

		await this.openModal(BrandAuthenticityModalComponent, {
			certificateId: this.certificate.id,
			identity: identityProp,
			isWeb: this.isWeb,
			isContentAuthentic: this.certificate.isContentAuthentic,
		});
	}

	async openTransparencyModal() {
		if (!this.certificate || !this.certificate.transparencyItems) return;

		const transparencyItems = mapTransparencyItemsToListItems(
			this.certificate.transparencyItems,
		);
		const { brandLogoSquare } = this.certificate.identity;

		await this.openModal(TransparencyModalComponent, {
			transparencyItems,
			brandLogoSquare: brandLogoSquare,
			isWeb: this.isWeb,
			nftLinkInfo: {
				isOwner: this.certificate.isOwner,
				isPending: this.isPendingCertificate(this.arianeeCertificate),
				certificateId: this.certificate.id,
				network: this.network,
				claimable: this.certificate.isRequestable,
			},
			header: {
				title: this.translate.instant('transparency'),
				icon: {
					name: 'icon-transparency',
					fontSize: 42,
					height: '72px',
					width: '72px',
					borderRadius: '16px',
				},
			},
		});
	}

	private async openModal<
		T extends Parameters<ModalController['create']>[0]['component'],
	>(component: T, componentProps: any) {
		const modal = await this.modalCtrl.create({
			component,
			cssClass: 'modal--bottom',
			swipeToClose: true,
			componentProps,
		});

		await modal.present();
	}

	async openTransferChoiceModal(type): Promise<void> {
		const modalTitle: string = this.translate.instant(
			'TransferCertificate.TransferOwnership',
		);

		const iconName: string = 'icon-transfer-ownership';

		const modal = await this.modalCtrl.create({
			component: TransferChoiceModalComponent,
			cssClass: 'modal--bottom',
			swipeToClose: true,
			componentProps: {
				type,
				certificate: this.certificate,
				isWeb: this.isWeb,
				header: {
					title: modalTitle,
					icon: {
						name: iconName,
						fontSize: 42,
						height: '72px',
						width: '72px',
						borderRadius: '16px',
					},
				},
			},
		});

		modal.onDidDismiss().then((data) => {
			if (data.data.transfer === 'transferWithArianee') {
				this.openTransferProofModal('transfer');
			} else if (data.data.transfer === 'transferAnyAddress') {
				this.openTransferAddressModal();
			}
		});
		this.eventLogger.logEvent('TransferChoice', {
			certificateId: this.certificate.id,
			issuer: this.certificate.identity.address,
			network: this.network,
		});
		return modal.present();
	}

	async openTransferProofModal(type: 'transfer' | 'proof'): Promise<void> {
		const modalTitle: string =
			type === 'transfer'
				? this.translate.instant('TransferCertificate.TransferOwnership')
				: this.translate.instant('TransferCertificate.shareOwnership');
		const iconName: string =
			type === 'transfer' ? 'icon-transfer-ownership' : 'icon-owner';
		const linkCodeCreation: () => Promise<{
			certificateId: number;
			passphrase: string;
			link: string;
		}> =
			type === 'proof'
				? createCertificateProofLink(this.wallet, this.arianeeCertificate)
				: createCertificateRequestOwnershipLink(
						this.wallet,
						this.arianeeCertificate,
				  );

		const modal = await this.modalCtrl.create({
			component: TransferProofModalComponent,
			cssClass: 'modal--bottom',
			swipeToClose: true,
			componentProps: {
				type,
				certificate: this.certificate,
				isWeb: this.isWeb,
				linkCodeCreation: linkCodeCreation,
				header: {
					title: modalTitle,
					icon: {
						name: iconName,
						fontSize: 42,
						height: '72px',
						width: '72px',
						borderRadius: '16px',
					},
				},
			},
		});
		return modal.present();
	}

	async openTransferAddressModal(): Promise<void> {
		const modalTitle: string = this.translate.instant(
			'TransferCertificate.TransferOwnership',
		);
		const iconName: string = 'icon-transfer-ownership';

		const imageUrl: string = this.carouselItems.find(
			(item) => item.type === 'picture',
		)
			? this.carouselItems.find((item) => item.type === 'picture').url
			: this.carouselItems.find((item) => item.type !== 'picture').preview;

		const modal = await this.modalCtrl.create({
			component: TransferAddressModalComponent,
			cssClass: 'modal--bottom',
			swipeToClose: true,
			componentProps: {
				certificate: this.arianeeCertificate,
				wallet: this.wallet,
				isWeb: this.isWeb,
				item: {
					url: imageUrl,
					legend: this.certificate.name,
				},
				header: {
					title: modalTitle,
					icon: {
						name: iconName,
						fontSize: 42,
						height: '72px',
						width: '72px',
						borderRadius: '16px',
					},
				},
			},
		});
		return modal.present();
	}

	isPendingCertificate(certificate: CertificateSummary): boolean {
		return (
			!!this.activatedRoute.snapshot.paramMap.get(
				'pendingCertificateIdOrUrl',
			) || this.pendingNftService.supportsDeferredClaim(certificate)
		);
	}

	async openClaimCongratsModal() {
		const pictureItem = this.carouselItems.find(
			(item) => item.type === 'picture',
		);
		const otherItem = this.carouselItems.find(
			(item) => item.type !== 'picture',
		);
		const imageUrl: string =
			this.carouselItems.length > 0
				? pictureItem
					? pictureItem.url
					: otherItem.preview
				: undefined;

		const modal = await this.modalCtrl.create({
			component: ClaimCongratsModalComponent,
			cssClass: 'auto-height middle hard-round',
			swipeToClose: true,
			componentProps: {
				title: this.translate.instant(
					'CertificateInfos.claimCongrat.congratulation',
				),
				description: this.translate.instant(
					'CertificateInfos.claimCongrat.description',
				),
				item: {
					url: imageUrl,
					legend: this.certificate.name,
				},
			},
		});

		modal.onDidDismiss().then(() => {
			this.displayPinCodeModal();
		});
		return modal.present();
	}

	displayPinCodeModal() {
		this.userService.hasPinCode
			.pipe(first())
			.subscribe(async (data: boolean) => {
				if (data === false) {
					const pinCodeModal = await this.modalCtrl.create({
						component: AuthPage,
						cssClass: 'modal--full_screen',
					});
					pinCodeModal.onDidDismiss().then(() => {
						this.backupCheckService.displayIfNeededBackUpModal().then();
					});
					await pinCodeModal.present();
				} else {
					this.backupCheckService.displayIfNeededBackUpModal().then();
				}
			});
	}

	async onSwipeComplete(loading: boolean) {
		if (this.arianeeCertificate) {
			return await this.arianeeNftClaim(loading);
		} else if (this.poapCertificate) {
			return await this.poapNftClaim(loading);
		}
	}

	private async arianeeNftClaim(loading: boolean) {
		this.claimLoading = {
			...this.claimLoading,
			label: this.translate.instant('Loader.fromBlockchain'),
			isLoading: loading,
		};

		if (this.isPendingCertificate(this.arianeeCertificate)) {
			await this.eventLogger.logEvent('onSwipeCompletePending', {
				certificateId: this.certificate.id,
				method: this.method,
				issuer: this.certificate.identity.address,
				network: this.network,
			});
			return this.onPendingNftClaim.emit();
		}

		await this.eventLogger.logEvent('onSwipeComplete', {
			certificateId: this.certificate.id,
			method: this.method,
			issuer: this.certificate.identity.address,
			network: this.network,
		});

		try {
			await this.wallet.methods.requestCertificateOwnership(
				+this.certificate.id,
				this.passphrase,
			);
			this.claimLoading = {
				...this.claimLoading,
				label: this.translate.instant('Loader.fromBlockchain'),
				isLoading: false,
			};
			await this.openClaimCongratsModal();
			await this.eventLogger.logEvent('onSwipeComplete_success', {
				certificateId: this.certificate.id,
				method: this.method,
				issuer: this.certificate.identity.address,
				network: this.network,
			});
		} catch (e) {
			this.claimLoading = {
				...this.claimLoading,
				label: this.translate.instant('Loader.fromBlockchain'),
				isLoading: false,
			};
			await this.eventLogger.logEvent('onSwipeComplete_error', {
				certificateId: this.certificate.id,
				method: this.method,
				issuer: this.certificate.identity.address,
				network: this.network,
			});
		}
		this.refreshNFT.emit();
	}

	private async poapNftClaim(loading: boolean) {
		this.claimLoading = {
			...this.claimLoading,
			label: this.translate.instant('Loader.fromBlockchain'),
			isLoading: loading,
		};

		await this.eventLogger.logEvent('onSwipeComplete', {
			certificateId: this.poapData.poapTokenId,
			type: 'poap',
			issuer: 'poap',
			network: this.network,
		});
		return this.poapService
			.claimPOAP({
				qr_hash: this.poapData.poapQRHash,
				secret: this.poapData.poapNFTSecret,
			})
			.pipe(
				take(1),
				tap(() => {
					this.certificate.isRequestable = false;
				}),
			)
			.subscribe((data: PoapClaimResponse) => {
				this.verifyPoapQueueBeforeRedirect(data.queue_uid);
			});
	}

	private verifyPoapQueueBeforeRedirect(queueId: string) {
		if (queueId) {
			timer(0, 3000)
				.pipe(
					mergeMap(() => {
						return this.poapService.getQueueStatus(queueId);
					}),
					filter((data: PoapQueueStatusResponse) => {
						const status = data.status.toLowerCase();
						return status === 'finish';
					}),
					first(),
				)
				.subscribe(async (data: PoapQueueStatusResponse) => {
					this.claimLoading = {
						...this.claimLoading,
						label: this.translate.instant('Loader.fromBlockchain'),
						isLoading: false,
					};
					await this.openClaimCongratsModal();
				});
		}
	}

	async openOnBoardingModal() {
		const modal = await this.modalCtrl.create({
			component: OnBoardingModalComponent,
			cssClass: 'modal--bottom',
		});
		return modal.present();
	}

	initFooter() {
		const chainType = getChainTypeFromNetwork(this.network);
		this.isClaimed = this.pendingNftService.isPendingNft(
			this.certificate ? this.certificate.id : null,
			chainType,
		);

		switch (this.method) {
			case 'requestOwnership':
				this.handleRequestOwnershipMethod();
				break;
			case 'proof':
				this.handleProofMethod();
				break;
			default:
				if (this.isClaimed && this.certificate.isRequestable) {
					this.nftStatus = {
						type: NftStatusType.SUCCESS,
						label: this.translate.instant('CertificateInfos.AlreadyClaimed'),
						message: undefined,
						icon: 'icon-owner',
					};
					break;
				} else {
					this.handleDefaultCase();
					break;
				}
		}

		this.displayNftStatus =
			this.nftStatus !== undefined && this.nftStatus !== null;
		this.displayClaimSlider =
			this.certificate &&
			!this.certificate.isOwner &&
			this.certificate.isRequestable &&
			!this.isClaimed &&
			!this.isWeb;
		this.displayClaimSliderContainer =
			this.displayNftStatus || this.displayClaimSlider;

		this.setSliderHeight();
	}

	handleRequestOwnershipMethod() {
		const hostedWalletContent =
			this.certificate &&
			this.certificate.identity.externalContents.find(
				(content) => content.type === 'hostedWallet',
			);

		if (
			this.certificate &&
			this.certificate.isRequestable === false &&
			!hostedWalletContent &&
			!this.certificate.isOwner
		) {
			this.nftStatus = {
				type: NftStatusType.ERROR,
				label: this.translate.instant('CertificateInfos.CannotGetOwnership'),
				message: undefined,
				icon: 'icon-not-owner',
			};
		} else if (
			this.certificate &&
			this.certificate.isRequestable === false &&
			!this.certificate.isOwner &&
			hostedWalletContent
		) {
			this.nftStatus = {
				type: NftStatusType.ERROR,
				message: this.translate.instant(
					'CertificateInfos.hostedWalletStatus.description',
					{ brandName: this.certificate.identity.brandName },
				),
				label: this.translate.instant(
					'CertificateInfos.hostedWalletStatus.title',
				),
				icon: 'icon-disconnected',
			};
		} else if (this.certificate && this.certificate.isOwner) {
			this.nftStatus = {
				type: NftStatusType.SUCCESS,
				label: this.translate.instant('CertificateInfos.AlreadyOwner'),
				message: undefined,
				icon: 'icon-owner',
			};
		} else if (this.isClaimed) {
			this.nftStatus = {
				type: NftStatusType.SUCCESS,
				label: this.translate.instant('CertificateInfos.AlreadyClaimed'),
				message: undefined,
				icon: 'icon-owner',
			};
		}
	}

	handleProofMethod() {
		if (this.proof && this.proof.isTrue === false) {
			if (this.proof.code === 'proof.token.tooold') {
				this.nftStatus = {
					type: NftStatusType.ERROR,
					message: undefined,
					label: this.translate.instant('CertificateInfos.proof.token.tooold'),
					icon: 'icon-moins',
				};
			} else {
				this.nftStatus = {
					type: NftStatusType.ERROR,
					message: dateFormatter(new Date(this.proof.timestamp)),
					label: this.translate.instant(
						'CertificateInfos.proof.token.dontmatch',
					),
					icon: 'icon-moins',
				};
			}
		} else if (this.proof && this.proof.isTrue === true) {
			this.nftStatus = {
				type: NftStatusType.SUCCESS,
				message: dateFormatter(new Date(this.proof.timestamp)),
				label: this.translate.instant('CertificateInfos.proof.token.valid'),
				icon: 'icon-check',
			};
		}
	}

	handleDefaultCase() {
		if (this.certificate && this.certificate.isOwner === true) {
			this.nftStatus = {
				type: NftStatusType.SUCCESS,
				label: this.translate.instant('CertificateInfos.AlreadyOwner'),
				message: undefined,
				icon: 'icon-owner',
			};
		} else {
			this.nftStatus = undefined;
		}
	}
}
