import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnDestroy,
	ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ToasterService } from '@arianeeprivate/wallet-shared-components';
import { NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { Button } from '../../../../../components/redesign/button/models/button.model';
import { ArianeeService } from '../../../../../providers/arianee-service/arianee.service';
import { EventLoggerService } from '../../../../../providers/event-logger/event-logger-service';
import { MnemonicService } from '../../../../../providers/mnemonic-service/mnemonic.service';

@Component({
	selector: 'app-backup-import-new',
	templateUrl: './backup-import-new.component.html',
	styleUrls: ['./backup-import-new.component.scss'],
})
export class BackupImportNewComponent implements AfterViewInit, OnDestroy {
	@ViewChild('header', {
		read: ElementRef,
		static: false,
	})
	header: ElementRef<HTMLElement>;

	public headerHeight: number = 0;
	public keyboardHeight: number = 0;

	public button: Button = {
		title: { text: 'backup' },
		buttonType: 'primary',
	};

	public buttonE2e: string = 'import';

	public textareaFocused: boolean = false;

	public mnemonic: string = '';
	public mnemonicValid: boolean;
	public currentWord: string = '';
	public $suggestion: ReplaySubject<string> = new ReplaySubject(1);

	private eventListeners: {
		target: EventTarget;
		name: string;
		function: EventListenerOrEventListenerObject;
	}[] = [];

	constructor(
		private translate: TranslateService,
		private mnemonicService: MnemonicService,
		private eventLogger: EventLoggerService,
		private router: Router,
		private navController: NavController,
		private toasterService: ToasterService,
		private arianeeService: ArianeeService,
		private changeDetectorRef: ChangeDetectorRef,
		private platform: Platform,
	) {}

	ngAfterViewInit() {
		this.log('screen', 'backup-import-new');
		this.setHeaderHeight();
		this.subscribeToKeyboardEvents();
	}

	ngOnDestroy(): void {
		this.eventListeners.forEach(({ target, name, function: fn }) => {
			target.removeEventListener(name, fn);
		});
	}

	private setHeaderHeight() {
		this.headerHeight = (
			this.header.nativeElement.firstElementChild as HTMLElement
		).offsetHeight;

		// Retry if css not yet loaded
		if (this.headerHeight === 0)
			setTimeout(this.ngAfterViewInit.bind(this), 10);
	}

	public get dictionary() {
		return MnemonicService.WORDS_DICTIONARY;
	}

	public onChangeCurrentWord(currentWord: string) {
		this.currentWord = currentWord.toLowerCase();
	}

	public onChange(change: { mnemonic: string[]; valid: boolean }) {
		const { valid, mnemonic } = change;

		this.mnemonic = mnemonic.join(' ');
		this.mnemonicValid = valid;

		const mnemonicLength = mnemonic.length;

		if (mnemonicLength >= 12) {
			this.updateButton({
				text: this.mnemonicValid
					? this.translate.instant('Import.import')
					: this.translate.instant('Import.invalidMnemonic'),
				color: this.mnemonicValid ? 'default' : 'invalid',
				disabled: false,
				loading: false,
				e2e: this.mnemonicValid ? 'import' : 'invalidMnemonic',
			});
		} else {
			this.updateButton({
				text: this.translate.instant('Import.import'),
				color: 'default',
				disabled: true,
				loading: false,
				e2e: 'import',
			});
		}
	}

	public onFocusChange(change: { focused: boolean }) {
		this.textareaFocused = change.focused;
	}

	public onClickBack() {
		this.navController.navigateBack(['/tab/setting']);
	}

	private updateButton({
		text,
		color,
		disabled,
		loading = false,
		e2e = 'import',
	}: {
		text: string;
		color: 'default' | 'invalid';
		disabled: boolean;
		loading: boolean;
		e2e: string;
	}) {
		let foreground = 'var(--neutral-0)';
		let background = 'var(--neutral-100)';

		if (!disabled) {
			foreground =
				color === 'default'
					? 'white'
					: getComputedStyle(document.documentElement).getPropertyValue(
							'--color-fg-danger',
					  );

			background =
				color === 'default'
					? 'var(--neutral-1000)'
					: getComputedStyle(document.documentElement).getPropertyValue(
							'--color-bg-danger',
					  );
		}

		this.button = {
			...this.button,
			title: {
				text,
				color: foreground,
			},
			customStyle: {
				...this.button.customStyle,
				backgroundColor: background,
			},
			disabled,
			loading,
		};

		this.buttonE2e = e2e;
	}

	private subscribeToKeyboardEvents() {
		const keyboardWillShow = (event: Event) => {
			const keyboardHeight = (event as unknown as { keyboardHeight: number })
				.keyboardHeight;

			if (this.platform.is('ios')) {
				this.keyboardHeight = keyboardHeight;
			}
			this.changeDetectorRef.detectChanges();
		};

		const keyboardWillHide = (event: Event) => {
			this.keyboardHeight = 0;
			this.changeDetectorRef.detectChanges();
		};

		window.addEventListener('keyboardWillShow', keyboardWillShow);
		window.addEventListener('keyboardWillHide', keyboardWillHide);

		this.eventListeners.push(
			{
				target: window,
				name: 'keyboardWillShow',
				function: keyboardWillShow,
			},
			{
				target: window,
				name: 'keyboardWillHide',
				function: keyboardWillHide,
			},
		);
	}

	private async log(type: 'screen' | 'event', name: string) {
		const address = await this.arianeeService.$address
			.pipe(take(1))
			.toPromise();

		if (type === 'screen') {
			this.eventLogger.logScreen(name, {
				publicKey: address,
			});
		} else {
			await this.eventLogger.logEvent(name, {
				publicKey: address,
			});
		}
	}

	public async import() {
		if (!this.mnemonicValid) return;

		try {
			this.updateButton({
				text: this.translate.instant('Import.import'),
				color: 'default',
				disabled: true,
				loading: true,
				e2e: 'import',
			});

			await this.mnemonicService.setNewMnemonic(this.mnemonic);
			await this.mnemonicService.markMnemonicAsBackedUp('manual');

			await this.log('event', 'backup-import-new_import_success');
			await this.router.navigate(['backup-import-success'], {
				replaceUrl: true,
			});
		} catch (e) {
			console.error(e);
			await this.log('event', 'backup-import-new_import_failure');
		} finally {
			this.updateButton({
				text: this.translate.instant('Import.import'),
				color: 'default',
				disabled: false,
				loading: false,
				e2e: 'import',
			});
		}
	}
}
