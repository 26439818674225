import { Component, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NETWORK } from '@arianee/arianeejs';
import {
	LoaderService,
	SharedLibraryTheme,
	StyleService,
	ToasterService,
	TranslationService,
} from '@arianeeprivate/wallet-shared-components';
import { NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Subject } from 'rxjs';
import { delay, take, tap } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { ArianeeBlockchainSyncService } from './providers/arianee-blockchain-sync-service/arianee-blockchain-sync.service';
import { ArianeeEventWatcherService } from './providers/arianee-event-watcher.service.ts/arianee-event-watcher.service';
import { ArianeeService } from './providers/arianee-service/arianee.service';
import { BackupCheckService } from './providers/backup-check-service/backup-check.service';
import { BackupService } from './providers/backup-service/backup.service';
import {
	EventLoggerService,
	IOSTrackingPermission,
} from './providers/event-logger/event-logger-service';
import { NotificationService } from './providers/notification-service/notification.service';
import { HandleInappLinkService } from './providers/scan-service/handle-link.service/handle-inapp-link.service';
import { HandleLinkService } from './providers/scan-service/handle-link.service/handle-link.service';
import { isWcDummyLink } from './providers/scan-service/handle-link.service/helpers/getWcLink';
import { ScanService } from './providers/scan-service/scan-service';
import { SessionService } from './providers/session-service/session.service';
import { StatusBarService } from './providers/statusbar/statusbar-service';
import { UserService } from './providers/user-service/user.service';
import WalletConnectV2Init from './providers/wallet-connect-v2-service/init';
import { WalletVersionService } from './providers/wallet-version-service/wallet-version.service';
import { ThemeService } from './providers/theme-service/theme.service';

declare const window: any;
declare const navigator: any;
declare const cordova: any;

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {
	isWeb: boolean = environment.appType === 'web';
	private network: NETWORK;
	private destroy$ = new Subject();
	constructor(
		private platform: Platform,
		private translate: TranslateService,
		private arianeeService: ArianeeService,
		private navCtrl: NavController,
		private router: Router,
		private inAppLink: HandleInappLinkService,
		private arianeeEventWatcherService: ArianeeEventWatcherService,
		private scanService: ScanService,
		private handleLinkService: HandleLinkService,
		private statusBar: StatusBarService,
		private walletVersionService: WalletVersionService,
		private eventLogger: EventLoggerService,
		private loaderService: LoaderService,
		private translationService: TranslationService,
		private notificationService: NotificationService,
		private userService: UserService,
		private translateService: TranslateService,
		private toasterService: ToasterService,
		private sessionService: SessionService,
		private backupService: BackupService,
		private bcSyncService: ArianeeBlockchainSyncService,
		private sharedStyleService: StyleService,
		private ngZone: NgZone,
		private walletConnectV2Init: WalletConnectV2Init,
		private backupCheckService: BackupCheckService,
		private themeService: ThemeService
	) {
		this.translate.setDefaultLang(this.translationService.defaultLanguage);
		this.translate.use(
			this.translationService.pickLanguageAccordingToUserLanguages(),
		);
		this.initializeApp();
		this.setCustomThemeForSharedComponents();
	}

	/**
	 * Customize Shared Library Theme
	 */
	setCustomThemeForSharedComponents(): void {
		const primary = getComputedStyle(document.documentElement).getPropertyValue(
			'--color-bg-primary',
		);
		const secondary = getComputedStyle(
			document.documentElement,
		).getPropertyValue('--color-bg-secondary');
		const action = getComputedStyle(document.documentElement).getPropertyValue(
			'--color-action-primary',
		);

		const text = getComputedStyle(document.documentElement).getPropertyValue(
			'--font-text',
		);
		const title = getComputedStyle(document.documentElement).getPropertyValue(
			'--font-title',
		);

		const theme: SharedLibraryTheme = {
			fonts: { title, text },
			colors: { primary, secondary, action },
		};

		this.sharedStyleService.setLibraryTheme(theme);
	}

	async initializeApp() {
		this.platform.resume
			.pipe(
				tap(() => this.eventLogger.logEvent('onResume')),
				delay(500),
				tap(() => {
					this.userService.initSecureStorage();
					this.branchInit(true);
				}),
				delay(2500),
				tap(() => this.bcSyncService.syncWithBlockchain(true)),
			)
			.subscribe();

		try {
			await this.platform.ready();
			this.eventLogger.logEvent('AppStart');
			if (this.platform.is('cordova') && this.platform.is('ios')) {
				if (window.cordova) {
					window.cordova.exec(
						(trackingPermission: IOSTrackingPermission) =>
							this.eventLogger.idfaRequestPermissionSuccess(trackingPermission),
						(payload: any) =>
							this.eventLogger.idfaRequestPermissionFailure(payload),
						'idfa',
						'requestPermission',
						[],
					);
				}
			}

			this.sessionService.resetSession();
			this.userService.initSecureStorage().catch(async (d) => {
				const translation = await this.translateService
					.get('Error.androidNotSecured.button')
					.toPromise();
				navigator.splashscreen.hide();
				return this.toasterService.alert({
					backdropDismiss: false,
					message: 'Error.androidNotSecured.message',
					buttons: [
						{
							text: translation,
							cssClass: 'secondary',
							handler: () => d.secureDevice(),
						},
					],
				});
			});

			this.userService.incrementNumberOfAppLaunch();

			this.statusBar.init();
			this.arianeeEventWatcherService.initWatch();

			moment.locale(this.translate.currentLang);

			this.scanService.init();
			this.branchInit();
			this.notificationService.init();

			this.arianeeService.$walletInitialize
				.pipe(take(1))
				.subscribe((network: NETWORK) => {
					this.backupService.init(network);

					if (this.platform.is('cordova')) {
						this.walletVersionService.check();
						navigator.splashscreen.hide();
					}
				});
		} catch (error) {
			this.walletVersionService.check();
			this.scanService.init();
			navigator.splashscreen.hide();

			this.branchInit();
		}

		await this.walletConnectV2Init.init();
		this.themeService.checkDarkMode();
	}

	async branchInit(resume = false) {
		const Branch = this.getBranchInstance();
		const data = await Branch.initSession();

		this.eventLogger.init(data.userId);
		if (!resume) {
			this.eventLogger.logEvent('appStart', '');
		}

		if (data['+clicked_branch_link'] && data.deeplink) {
			this.processDeeplink(
				'deeplink_branchio',
				data['+clicked_branch_link'],
				data.deeplink,
			);
		} else if (data['+non_branch_link']) {
			const link = data['+non_branch_link'];
			this.processDeeplink('deeplink_native', link, link, 250);
		} else {
			await this.backupCheckService.displayIfNeededBackUpModal();
		}
	}

	getBranchInstance() {
		if (!this.platform.is('cordova')) {
			return (
				window.mockBranch ||
				(window.mockBranch = {
					initSession: () =>
						Promise.resolve({
							'+clicked_branch_link': false,
							deeplink: 'foo',
						}),
				})
			);
		}
		return window.Branch;
	}

	async processDeeplink(eventType, link, targetLink, delay = 0) {
		this.eventLogger.logEvent(eventType, { link });
		if (isWcDummyLink(targetLink)) return;

		this.ngZone.run(() => {
			this.loaderService.showLoader();
		});

		this.ngZone.run(() => {
			this.loaderService.showLoader();

			setTimeout(async () => {
				await this.handleBranchLink(link);
			}, 250);
		});
	}

	handleBranchLink = async (link: string) => {
		this.handleLinkService.handleLink(link).subscribe(async (objectLink) => {
			if (objectLink.certificateId) {
				const network = objectLink.network || NETWORK.mainnet;
				this.loaderService.showLoader({ label: 'productDetail' });

				const nextUrl = `/tab/brand-list/product-detail/${network}/${objectLink.certificateId}/${objectLink.passphrase}/${objectLink.method}`;
				if (this.router.url === nextUrl) {
					await this.navCtrl.pop();
				}

				await this.handleLinkService.redirectWithFirstNavigation(nextUrl);
			}
		});
	};

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
