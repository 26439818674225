import {
	AfterViewInit,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { get } from 'lodash';
import { Subscription } from 'rxjs';

import { NFTCardProps } from '../../../../../components/redesign/nft-card/nft-card.component';
import { MultichainCertificateSummary } from '../../../../../providers/arianee-blockchain-proxy-service/arianee-blockchain-proxy-service';
import { EventLoggerService } from '../../../../../providers/event-logger/event-logger-service';
import { MyCertificatesService } from '../../../../../providers/my-certificates/my-certificates.service';
import { PendingNftService } from '../../../../../providers/pending-nft-service/pending-nft.service';
import { UserService } from '../../../../../providers/user-service/user.service';
import { ChainType } from '../../../../../types/multichain';
import { MappedCertificate } from '../../../product-detail/models/mapped-certificate.model';
import { certificateSummaryMapper } from '../../../product-detail/utils/mappers/certificate-summary/certificate-summary-mapper';
import { getMediaCarouselItemFromCertificate } from '../../../product-detail/utils/mappers/media-carousel/media-carousel-mapper';

type AugmentedNFTCardProps = NFTCardProps & {
	certificateId: MultichainCertificateSummary['certificateId'];
};

@Component({
	selector: 'app-brand-product-list-new',
	templateUrl: './brand-product-list-new.page.html',
	styleUrls: ['./brand-product-list-new.page.scss'],
})
export class BrandProductListNewPage
	implements OnInit, AfterViewInit, OnDestroy
{
	@ViewChild('header', {
		read: ElementRef,
		static: false,
	})
	header: ElementRef<HTMLElement>;

	public headerHeight: number = 0;
	public isLoading = true;
	public brandName: string;
	public brandLogoUrl: string = '';
	public nftCards: AugmentedNFTCardProps[] = [];
	public isMonoBrand: boolean = true;

	private issuerAddress: string;
	private chainType: ChainType;

	private subscriptions: Subscription[] = [];

	constructor(
		private activatedRoute: ActivatedRoute,
		private navCtrl: NavController,
		private userService: UserService,
		private myCertificatesService: MyCertificatesService,
		private eventLogger: EventLoggerService,
		private pendingNftService: PendingNftService,
		private router: Router,
	) {}

	ngOnInit() {
		this.setIssuerAddress();
		this.setChainType();
		this.retrieveCertificates();

		this.eventLogger.logScreen('brand-product-list', {
			brandId: this.issuerAddress,
		});
	}

	ngAfterViewInit() {
		this.setHeaderHeight();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}

	private setHeaderHeight() {
		this.headerHeight = (
			this.header.nativeElement.firstElementChild as HTMLElement
		).offsetHeight;

		// Retry if css not yet loaded
		if (this.headerHeight === 0)
			setTimeout(this.ngAfterViewInit.bind(this), 10);
	}

	private setIsMonoBrand(issuersCount: number) {
		this.isMonoBrand = issuersCount === 1;
	}

	public onClickNftCard(productProps: AugmentedNFTCardProps) {
		const { network, certificateId } = productProps;

		if (productProps.isPending) {
			this.navCtrl.navigateForward(
				`/tab/brand-list/pending/${network}/${certificateId}`,
			);
		} else {
			this.navCtrl.navigateForward(
				`/tab/brand-list/product-detail/${network}/${certificateId}`,
			);
		}
	}

	private setChainType() {
		const chainTypeSub = this.userService.$chainType
			.get()
			.subscribe((chainType) => {
				this.chainType = chainType;
			});

		this.subscriptions.push(chainTypeSub);
	}

	private setIssuerAddress() {
		this.issuerAddress = this.activatedRoute.snapshot.paramMap.get('brandId');
	}

	private retrieveCertificates() {
		this.myCertificatesService
			.getCertificatesByIssuer(this.issuerAddress)
			.subscribe(async ({ certificates, totalIssuers }) => {
				if (!certificates || certificates.length === 0) {
					this.navCtrl.navigateBack('/tab/brand-list');
					return;
				}

				const [{ brandName, brandLogoUrl }, nftCards] = await Promise.all([
					this.getBrandNameAndLogoFrom(certificates[0]),
					this.mapCertificatesToAugmentedNftCards(certificates),
				]);

				this.setIsMonoBrand(totalIssuers);
				this.brandName = brandName;
				this.brandLogoUrl = brandLogoUrl;
				this.nftCards = nftCards;
				this.isLoading = false;

				if (this.nftCards.length === 0) {
					this.redirectToCollectionList();
				}
			});
	}

	redirectToCollectionList() {
		this.router.navigate(['/tab/brand-list']);
	}

	private async getBrandNameAndLogoFrom(
		firstCertificate: MultichainCertificateSummary,
	) {
		const mappedCertificate = await certificateSummaryMapper(firstCertificate);

		const brandName = get(
			firstCertificate,
			'issuer.identity.data.name',
			'brand name is missing',
		);

		const brandLogoUrl = get(mappedCertificate, 'identity.brandLogoSquare', '');

		return {
			brandName,
			brandLogoUrl,
		};
	}

	private async mapCertificatesToAugmentedNftCards(
		certificates: MultichainCertificateSummary[],
	): Promise<AugmentedNFTCardProps[]> {
		return Promise.all(
			certificates.map(async (certificate) => {
				const mappedCertificate = await certificateSummaryMapper(certificate);

				const imageUrl = this.getNftCardImageUrl(mappedCertificate);

				const backgroundUrl = this.getNftCardBackgroundUrl(mappedCertificate);

				const isPending = this.pendingNftService.isPendingNft(
					certificate.certificateId,
					this.chainType,
				);

				return {
					isPending,
					backgroundUrl,
					imageUrl,
					name: mappedCertificate.name,
					network: certificate.network,
					certificateId: certificate.certificateId,
				};
			}),
		);
	}

	private getNftCardImageUrl(mappedCertificate: MappedCertificate) {
		const mediaCarouselItems =
			getMediaCarouselItemFromCertificate(mappedCertificate);

		let firstMediaUrl = '';

		const firstCarouselItem = get(mediaCarouselItems, '[0]', null);

		if (firstCarouselItem) {
			firstMediaUrl = firstCarouselItem.preview
				? firstCarouselItem.preview
				: firstCarouselItem.url;
		}

		return firstMediaUrl;
	}

	private getNftCardBackgroundUrl(
		mappedCertificate: MappedCertificate,
	): string | null {
		const identityItemBackgroundPicture = get(
			mappedCertificate,
			'identity.itemBackgroundPicture',
			null,
		);

		const certificateItemBackgroundPicture: { url: string } = get(
			get(mappedCertificate, 'medias', []).find(
				(media) => media.type === 'itemBackgroundPicture',
			),
			'url',
			null,
		);

		return certificateItemBackgroundPicture || identityItemBackgroundPicture;
	}

	public onClickBack() {
		this.navCtrl.navigateBack(['/tab/brand-list']);
	}
}
