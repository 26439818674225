import { Location } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnDestroy,
	ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { Button } from '../../../../../components/redesign/button/models/button.model';
import { ArianeeService } from '../../../../../providers/arianee-service/arianee.service';
import { EventLoggerService } from '../../../../../providers/event-logger/event-logger-service';
import { MnemonicService } from '../../../../../providers/mnemonic-service/mnemonic.service';
import { UserService } from '../../../../../providers/user-service/user.service';

@Component({
	selector: 'app-backup-sort-new',
	templateUrl: './backup-sort-new.component.html',
	styleUrls: ['./backup-sort-new.component.scss'],
})
export class BackupSortNewComponent implements AfterViewInit, OnDestroy {
	@ViewChild('header', {
		read: ElementRef,
		static: false,
	})
	header: ElementRef<HTMLElement>;

	public headerHeight: number = 0;
	public keyboardHeight: number = 0;

	public button: Button = {
		title: { text: 'backup', color: 'black' },
	};

	public buttonE2e: string = 'backup';

	public textareaFocused: boolean = false;

	public mnemonic: string = '';
	public expectedMnemonic: string = '';
	public mnemonicValid: boolean;
	public currentWord: string = '';
	public $suggestion: ReplaySubject<string> = new ReplaySubject(1);

	private eventListeners: {
		target: EventTarget;
		name: string;
		function: EventListenerOrEventListenerObject;
	}[] = [];

	constructor(
		private translate: TranslateService,
		private mnemonicService: MnemonicService,
		private eventLogger: EventLoggerService,
		private router: Router,
		private location: Location,
		private userService: UserService,
		private arianeeService: ArianeeService,
		private changeDetectorRef: ChangeDetectorRef,
		private platform: Platform,
	) {}

	ngAfterViewInit() {
		this.log('screen', 'backup-sort-new');
		this.setHeaderHeight();
		this.subscribeToKeyboardEvents();
		this.setExpectedMnemonic();
	}

	ngOnDestroy(): void {
		this.eventListeners.forEach(({ target, name, function: fn }) => {
			target.removeEventListener(name, fn);
		});
	}

	private async setExpectedMnemonic() {
		this.expectedMnemonic = await this.userService
			.getMnemonic()
			.pipe(take(1))
			.toPromise();
	}

	private setHeaderHeight() {
		this.headerHeight = (
			this.header.nativeElement.firstElementChild as HTMLElement
		).offsetHeight;

		// Retry if css not yet loaded
		if (this.headerHeight === 0)
			setTimeout(this.ngAfterViewInit.bind(this), 10);
	}

	public get dictionary() {
		return MnemonicService.WORDS_DICTIONARY;
	}

	public onChangeCurrentWord(currentWord: string) {
		this.currentWord = currentWord.toLowerCase();
	}

	public onChange(change: { mnemonic: string[]; valid: boolean }) {
		const { valid, mnemonic } = change;

		this.mnemonic = mnemonic.join(' ');
		this.mnemonicValid = valid && this.mnemonic === this.expectedMnemonic;

		const mnemonicLength = mnemonic.length;

		if (mnemonicLength >= 12) {
			this.updateButton({
				text: this.mnemonicValid
					? this.translate.instant('Backup.backupManuallyBtn')
					: this.translate.instant('Import.invalidMnemonic'),
				color: this.mnemonicValid ? 'default' : 'invalid',
				disabled: false,
				loading: false,
				e2e: this.mnemonicValid ? 'backup' : 'invalidMnemonic',
			});
		} else {
			this.updateButton({
				text: this.translate.instant('Backup.backupManuallyBtn'),
				color: 'default',
				disabled: true,
				loading: false,
				e2e: 'backup',
			});
		}
	}

	public onFocusChange(change: { focused: boolean }) {
		this.textareaFocused = change.focused;
	}

	public onClickBack() {
		this.location.back();
	}

	private updateButton({
		text,
		color,
		disabled,
		loading = false,
		e2e = 'backup',
	}: {
		text: string;
		color: 'default' | 'invalid';
		disabled: boolean;
		loading: boolean;
		e2e: string;
	}) {
		let foreground = 'var(--neutral-0)';
		let background = 'var(--neutral-100)';

		if (!disabled) {
			foreground =
				color === 'default'
					? 'var(--neutral-1000)'
					: getComputedStyle(document.documentElement).getPropertyValue(
							'--color-fg-danger',
					  );

			background =
				color === 'default'
					? 'var(--neutral-1000)'
					: getComputedStyle(document.documentElement).getPropertyValue(
							'--color-bg-danger',
					  );
		}

		this.button = {
			...this.button,
			title: {
				text,
				color: foreground,
			},
			buttonType: 'primary',
			customStyle: {
				...this.button.customStyle,
				backgroundColor: background,
			},
			disabled,
			loading,
		};

		this.buttonE2e = e2e;
	}

	private subscribeToKeyboardEvents() {
		const keyboardWillShow = (event: Event) => {
			const keyboardHeight = (event as unknown as { keyboardHeight: number })
				.keyboardHeight;

			if (this.platform.is('ios')) {
				this.keyboardHeight = keyboardHeight;
			}
			this.changeDetectorRef.detectChanges();
		};

		const keyboardWillHide = (event: Event) => {
			this.keyboardHeight = 0;
			this.changeDetectorRef.detectChanges();
		};

		window.addEventListener('keyboardWillShow', keyboardWillShow);
		window.addEventListener('keyboardWillHide', keyboardWillHide);

		this.eventListeners.push(
			{
				target: window,
				name: 'keyboardWillShow',
				function: keyboardWillShow,
			},
			{
				target: window,
				name: 'keyboardWillHide',
				function: keyboardWillHide,
			},
		);
	}

	private async log(type: 'screen' | 'event', name: string) {
		const address = await this.arianeeService.$address
			.pipe(take(1))
			.toPromise();

		if (type === 'screen') {
			this.eventLogger.logScreen(name, {
				publicKey: address,
			});
		} else {
			await this.eventLogger.logEvent(name, {
				publicKey: address,
			});
		}
	}

	public async confirm() {
		if (!this.mnemonicValid) return;

		try {
			this.updateButton({
				text: this.translate.instant('Backup.backupManuallyBtn'),
				color: 'default',
				disabled: true,
				loading: true,
				e2e: 'backup',
			});

			await this.mnemonicService.markMnemonicAsBackedUp('manual');
			await this.router.navigate(['/backup-manually-success'], {
				replaceUrl: true,
			});

			await this.log('event', 'backup-sort-new_backup_success');
		} catch (e) {
			console.error(e);
			await this.log('event', 'backup-sort-new_backup_failure');
		} finally {
			this.updateButton({
				text: this.translate.instant('Backup.backupManuallyBtn'),
				color: 'default',
				disabled: false,
				loading: false,
				e2e: 'backup',
			});
			this.userService.setHasBackup(true);
		}
	}
}
