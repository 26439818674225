import { Icon } from '../../../../../../components/redesign/icon/models/icon.model';
import { ListItem } from '../../../../../../components/redesign/list/models/list-item.model';
import { BrowserService } from '../../../../../../providers/inapp-browser/inapp-browser-service';
import {
	ExternalContent,
	SocialMedia,
	TransparencyItem,
	TransparencyV1Item,
	TransparencyV2Item,
} from '../../../models/mapped-certificate.model';
import {
	ExternalContentService,
	NftLinkInfo,
} from '../../externalContentsHandler';

const EXTERNAL_CONTENT_LINK_TYPES: ExternalContent['type'][] = [
	'website',
	'arianeeAccessTokenAuthLink',
	'youtube',
	'proofLinkAction',
	'spkzLink',
	'system',
];

const mapExternalContentsToLinksListItems = (
	externalContents: ExternalContent[],
	browserService: BrowserService,
	nftLinkInfo: NftLinkInfo,
	externalContentService: ExternalContentService,
): ListItem[] => {
	return (externalContents || [])
		.map<ListItem>(({ title, url, type }) => {
			if (!EXTERNAL_CONTENT_LINK_TYPES.includes(type)) {
				return null;
			}
			return {
				icon: {
					name: 'icon-link',
					fontSize: 24,
					color:
						!nftLinkInfo.isOwner &&
						(type === 'arianeeAccessTokenAuthLink' ||
							type === 'spkzLink' ||
							type === 'proofLinkAction')
							? '#9799A3'
							: null,
				},
				title: title,
				actionIcon: 'link',
				disabled:
					!nftLinkInfo.isOwner &&
					(type === 'arianeeAccessTokenAuthLink' ||
						type === 'spkzLink' ||
						type === 'proofLinkAction'),
				action: () =>
					externalContentService.onLinkClick(
						{
							type,
							url,
							title,
						},
						nftLinkInfo,
					),
			};
		})
		.filter((item) => !!item);
};

const SOCIAL_NETWORK_LINK_PATTERN = {
	facebook: 'https://www.facebook.com/%handle',
	instagram: 'https://www.instagram.com/%handle',
	twitter: 'https://twitter.com/%handle',
	youtube: 'https://www.youtube.com/@%handle',
};

function capitalizeFirstLetter(str: string) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

const mapSocialMediasToListItems = (
	socialMedias: SocialMedia[],
	browserService: BrowserService,
): ListItem[] => {
	return socialMedias.map(({ type, value }) => {
		const socialNetworkLink = value.match(/^http(s)*:/)
			? value
			: SOCIAL_NETWORK_LINK_PATTERN[type].replace('%handle', value);

		return {
			icon: {
				name: 'icon-' + type,
				fontSize: 24,
			},
			title: capitalizeFirstLetter(type),
			actionIcon: 'link',
			action: () => browserService.openBrowser(socialNetworkLink),
		};
	});
};

const TRANSPARENCY_BASE_ICON: Partial<Icon> = {
	width: '40px',
	height: '40px',
	backgroundColor: 'transparent',
	border: '1px solid rgba(0, 0, 0, 0.06)',
	borderRadius: '35px',
	image: {
		width: '40px',
		height: '40px',
	},
};
const TRANSPARENCY_CATEGORY_TO_ICON: Record<
	TransparencyV1Item['type'] | TransparencyV2Item['category'],
	Icon
> = {
	material: {
		...TRANSPARENCY_BASE_ICON,
		image: {
			...TRANSPARENCY_BASE_ICON.image,
			url: 'assets/imgs/Materiaux.png',
		},
	},
	assembly: {
		...TRANSPARENCY_BASE_ICON,
		image: {
			...TRANSPARENCY_BASE_ICON.image,
			url: 'assets/imgs/Manufacture.png',
		},
	},
	impact: {
		...TRANSPARENCY_BASE_ICON,
		image: {
			...TRANSPARENCY_BASE_ICON.image,
			url: 'assets/imgs/Impact.png',
		},
	},
	Assembly: {
		...TRANSPARENCY_BASE_ICON,
		image: {
			...TRANSPARENCY_BASE_ICON.image,
			url: 'assets/imgs/Manufacture.png',
		},
	},
	Materials: {
		...TRANSPARENCY_BASE_ICON,
		image: {
			...TRANSPARENCY_BASE_ICON.image,
			url: 'assets/imgs/Materiaux.png',
		},
	},
	Environment: {
		...TRANSPARENCY_BASE_ICON,
		image: {
			...TRANSPARENCY_BASE_ICON.image,
			url: 'assets/imgs/Impact.png',
		},
	},
	Label: {
		...TRANSPARENCY_BASE_ICON,
		image: {
			...TRANSPARENCY_BASE_ICON.image,
			url: '',
		},
	},
};

const mapTransparencyItemsToListItems = (
	transparencyItems: TransparencyItem[],
): ListItem<TransparencyItem>[] => {
	return transparencyItems.map((transparencyItem) => {
		let icon: Icon;

		if (transparencyItem.transparencyType === 'v1') {
			icon = transparencyItem.avatar
				? {
						...TRANSPARENCY_BASE_ICON,
						image: {
							...TRANSPARENCY_BASE_ICON.image,
							url: transparencyItem.avatar,
						},
				  }
				: TRANSPARENCY_CATEGORY_TO_ICON[transparencyItem.type];
		} else if (transparencyItem.transparencyType === 'v2') {
			const customIconMedia = (transparencyItem.medias || []).find(
				(media) => media.type === 'icon',
			);
			icon = customIconMedia
				? {
						...TRANSPARENCY_BASE_ICON,
						image: {
							...TRANSPARENCY_BASE_ICON.image,
							url: customIconMedia.url,
						},
				  }
				: TRANSPARENCY_CATEGORY_TO_ICON[transparencyItem.category];
		}

		return {
			icon,
			title: transparencyItem.title,
			subtitle: transparencyItem.subtitle,
			actionIcon: 'link',
			action: () => {},
			original: transparencyItem,
		};
	});
};

export {
	mapExternalContentsToLinksListItems,
	mapSocialMediasToListItems,
	mapTransparencyItemsToListItems,
};
