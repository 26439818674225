import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Icon } from '../icon/models/icon.model';
import { ListItem } from '../list/models/list-item.model';
import { DescriptionModalComponent } from './description-modal/description-modal.component';

@Component({
	selector: 'app-description',
	templateUrl: './description.component.html',
	styleUrls: ['./description.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DescriptionComponent implements OnInit {
	@Input() modalHeader: { title: string; icon?: Icon };
	@Input() text: string;
	@Input() propertiesItems: ListItem[] = undefined;
	@Input() isWeb: boolean;

	constructor(private modalCtrl: ModalController) {}

	ngOnInit() {}

	async openModal() {
		const modal = await this.modalCtrl.create({
			component: DescriptionModalComponent,
			cssClass: 'modal--bottom',
			swipeToClose: true,
			componentProps: {
				description: this.text,
				title: this.modalHeader.title,
				icon: this.modalHeader.icon,
				propertiesItems: this.propertiesItems,
				isWeb: this.isWeb,
			},
		});
		return modal.present();
	}
}
