import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {backupTypeEnum, ToasterService,} from '@arianeeprivate/wallet-shared-components';
import {ModalController, Platform, PopoverController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

import {BackupService} from '../../../providers/backup-service/backup.service';
import {UserService} from '../../../providers/user-service/user.service';
import {Button} from '../button/models/button.model';
import {Icon} from '../icon/models/icon.model';

@Component({
	selector: 'app-backup-choice-modal',
	templateUrl: './backup-choice-modal.component.html',
	styleUrls: ['./backup-choice-modal.component.scss'],
})
export class BackupChoiceModalComponent implements OnInit {
	@Input() header: { title: string; icon: Icon };
	modal: HTMLIonModalElement;

	backupCloudButton: Button = {
		title: {
			text: 'Backup with Google Drive',
		},
		buttonType:'primary',
		loading: false,
		disabled: false,
		actionFinished: false,
	};

	lastCheck = {
		isBackedUp: false,
		date: undefined,
		email: undefined,
	};

	backupManuallyButton: Button;

	lastManualCheck = {
		isBackedUp: false,
		date: undefined,
		email: undefined,
	};

	constructor(
		private modalCtrl: ModalController,
		private platform: Platform,
		private backupService: BackupService,
		private popOverCtrl: PopoverController,
		private toasterService: ToasterService,
		private router: Router,
		private translate: TranslateService,
		private userService: UserService,
	) {}

	async ngOnInit() {
		this.modal = await this.modalCtrl.getTop();
		this.initBackupStatus();
		this.initBackupCloudButton();
		this.initBackupManuallyButton();
	}

	private initBackupManuallyButton() {
		this.backupManuallyButton = {
			title: {
				text: this.translate.instant('Backup.manualBackup'),
			},
			loading: false,
			disabled: false,
			actionFinished: false,
			buttonType: 'secondary'
		};
	}

	private initBackupCloudButton() {
		if (this.platform.is('ios')) {
			this.backupCloudButton = {
				...this.backupCloudButton,
				title: {
					...this.backupCloudButton.title,
					text: this.translate.instant('Backup.icloudBackup'),
				},
			};
		} else {
			this.backupCloudButton = {
				...this.backupCloudButton,
				title: {
					...this.backupCloudButton.title,
					text: this.translate.instant('Backup.driveBackup'),
				},
			};
		}
	}

	initBackupStatus() {
		this.backupService.getBackupCurrentWallet().subscribe((data: any[]) => {
			if (data.length > 0) {
				this.lastManualCheck =
					data.find((item) => item.type === backupTypeEnum.manual) ||
					this.lastManualCheck;
				this.lastCheck =
					data.find(
						(item) =>
							item.type === backupTypeEnum.google ||
							item.type === backupTypeEnum.icloud,
					) || this.lastCheck;
			}
		});
	}

	onCloseModal() {
		this.modalCtrl.dismiss();
	}

	onBackupManually() {
		this.router.navigate(['/backup-viewer']);
		this.modalCtrl.dismiss();
	}

	onSubmitAutomaticBackup() {
		if (this.lastCheck.isBackedUp) {
			this.modalCtrl.dismiss();
			return this.validateBackup();
		} else {
			this.modalCtrl.dismiss();
			return this.automaticBackup();
		}
	}

	async automaticBackup() {
		try {
			await this.backupService.backupMnemonic();
			await this.backupService.checkBackup();

			this.toasterService.showRedesigned({
				position: 'top',
				duration: 5000,
				title: 'Backup.automaticBackup.onSuccess',
				icon: 'check',
				color: 'success',
			});
		} catch (e) {
			console.error(e);
			this.toasterService.showRedesigned({
				position: 'top',
				duration: 5000,
				title: 'Backup.validateBackup.onError',
				icon: 'cancel',
				color: 'danger',
			});
		}
	}

	async validateBackup() {
		try {
			await this.backupService.checkBackup();
			this.toasterService.showRedesigned({
				position: 'top',
				duration: 5000,
				title: 'Backup.validateBackup.onSuccess',
				icon: 'check',
				color: 'success',
			});
			this.userService.setHasBackup(true);
		} catch (e) {
			this.toasterService.alert({
				message: 'Backup.validateBackup.onError',
			});
		}
	}
}
