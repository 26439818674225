import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';

import {Button} from './models/button.model';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnChanges {
	@Input() button: Button;

	@Input() enableLoading: boolean = true;

	@Input() e2eTag: string = '';

	@Output() clickEvent = new EventEmitter<any>();

	constructor(private cdr: ChangeDetectorRef) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes) {
			this.cdr.detectChanges();
		}
	}

	onClick() {
		this.clickEvent.emit();
	}
}
