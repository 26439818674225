import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {backupTypeEnum, ToasterService,} from '@arianeeprivate/wallet-shared-components';
import {ModalController, Platform, PopoverController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

import {BackupService} from '../../../providers/backup-service/backup.service';
import {FeatureFlipService} from '../../../providers/feature-flip-service/feature-flip.service';
import {ImportPopoverComponent} from '../../import-popover/import-popover.component';
import {Button} from '../button/models/button.model';
import {Icon} from '../icon/models/icon.model';

@Component({
	selector: 'app-import-choice-modal',
	templateUrl: './import-choice-modal.component.html',
	styleUrls: ['./import-choice-modal.component.scss'],
})
export class ImportChoiceModalComponent implements OnInit {
	@Input() header: { title: string; icon: Icon };
	modal: HTMLIonModalElement;

	importCloudButton: Button = {
		title: {
			text: 'Backup with Google Drive',
		},
		buttonType: 'primary',
		loading: false,
		disabled: false,
		actionFinished: false,
	};

	lastCheck = {
		isBackedUp: false,
		date: undefined,
		email: undefined,
	};

	importManuallyButton: Button;

	lastManualCheck = {
		isBackedUp: false,
		date: undefined,
		email: undefined,
	};

	constructor(
		private modalCtrl: ModalController,
		private platform: Platform,
		private backupService: BackupService,
		private popOverCtrl: PopoverController,
		private toasterService: ToasterService,
		private router: Router,
		private translate: TranslateService,
		private featureFlipService: FeatureFlipService,
	) {}

	async ngOnInit() {
		this.modal = await this.modalCtrl.getTop();
		this.initBackupStatus();
		this.initBackupCloudButton();
		this.initBackupManuallyButton();
	}

	private initBackupManuallyButton() {
		this.importManuallyButton = {
			title: {
				text: this.translate.instant('Backup.startImportManually'),
				color: 'black',
			},
			loading: false,
			disabled: false,
			actionFinished: false,
			buttonType: 'secondary',
		};
	}

	private initBackupCloudButton() {
		if (this.platform.is('ios')) {
			this.importCloudButton = {
				title: {
					text: this.translate.instant('Backup.importIcloudBackup'),
				},
				loading: false,
				disabled: false,
				actionFinished: false,
				buttonType: 'primary'
			};
		} else {
			this.importCloudButton = {
				title: {
					text: this.translate.instant('Backup.importGoogleDriveBackup'),
				},
				loading: false,
				disabled: false,
				actionFinished: false,
				buttonType: 'primary'
			};
		}
	}

	initBackupStatus() {
		this.backupService.getBackupCurrentWallet().subscribe((data: any[]) => {
			if (data.length > 0) {
				this.lastManualCheck =
					data.find((item) => item.type === backupTypeEnum.manual) ||
					this.lastManualCheck;
				this.lastCheck =
					data.find(
						(item) =>
							item.type === backupTypeEnum.google ||
							item.type === backupTypeEnum.icloud,
					) || this.lastCheck;
			}
		});
	}

	onCloseModal() {
		this.modalCtrl.dismiss();
	}

	onBackupManually() {
		this.router.navigate(['/backup-import']);
		this.modalCtrl.dismiss();
	}

	async onImportBackup() {
		try {
			await this.modalCtrl.dismiss();

			const newSettingsFeatureEnabled = await this.featureFlipService
				.$isFeatureFlipOnce('newSettingsPage')
				.toPromise();

			if (newSettingsFeatureEnabled) {
				this.router.navigate(['/cloud-import']);
			} else {
				const backups = await this.backupService.getBackupsId();
				const prodPopover = await this.popOverCtrl.create({
					component: ImportPopoverComponent,
					translucent: true,
					cssClass: 'backup-popover',
					componentProps: {
						backups,
					},
				});
				await prodPopover.present();
			}
		} catch (e) {
			await this.modalCtrl.dismiss();
			await this.toasterService.alert({
				message: 'Backup.importBackup.onError',
			});
		}
	}
}
