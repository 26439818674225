import {Component, Input, OnInit} from '@angular/core';
import {ArianeeWallet} from '@arianee/arianeejs/dist/src/core/wallet';
import {CertificateSummary} from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

import {environment} from '../../../../../../environments/environment';
import {Button} from '../../../../../components/redesign/button/models/button.model';
import {Icon} from '../../../../../components/redesign/icon/models/icon.model';
import {IsAuthGuard} from '../../../../../guards/isAuth.guard';
import {transferCertificate} from '../../../../../helpers/wallet-methods';
import {EventLoggerService} from '../../../../../providers/event-logger/event-logger-service';
import {BrowserService} from '../../../../../providers/inapp-browser/inapp-browser-service';
import {SharingService} from '../../../../../providers/sharing-service/sharing.service';

@Component({
	selector: 'app-transfer-address-modal',
	templateUrl: './transfer-address-modal.component.html',
	styleUrls: ['./transfer-address-modal.component.scss'],
})
export class TransferAddressModalComponent implements OnInit {
	@Input() header: { title: string; icon: Icon };
	@Input() wallet: ArianeeWallet;
	@Input() certificate: CertificateSummary;
	@Input() item: { url: string; legend: string };
	@Input() isWeb: boolean;

	displayLoader = false;

	modal: HTMLIonModalElement;

	confirmButton: Button;

	backButton: Button;

	nextButton: Button;

	viewTxButton: Button;

	backToHomeButton: Button;

	address: string = '';

	step: 'input' | 'confirm' | 'transfer' = 'input';

	receipt;

	appDisplayName = environment.appDisplayName;

	constructor(
		private sharingService: SharingService,
		private translateService: TranslateService,
		protected modalCtrl: ModalController,
		protected authGuard: IsAuthGuard,
		private browserService: BrowserService,
		private eventLogger: EventLoggerService,
	) {}

	async ngOnInit() {
		this.modal = await this.modalCtrl.getTop();

		this.eventLogger.logEvent('TransferToAnyAddressForm', {
			certificateId: this.certificate.certificateId,
			issuer: this.certificate.issuer.identity.address,
			network: this.wallet.configuration.networkName,
		});

		const authenticated = await this.authGuard.isAuth();
		if (!authenticated) {
			await this.modal.dismiss();
			return;
		}

		this.initButtons();
	}

	onCloseModal(): void {
		this.modalCtrl.dismiss({
			dismissed: true,
		});
	}

	async onTransfer() {
		this.step = 'transfer';
		this.displayLoader = true;

		const transfer: () => Promise<{}> = transferCertificate(
			this.wallet,
			this.certificate,
			this.address,
		);

		this.receipt = await transfer();
		this.displayLoader = false;
		this.eventLogger.logEvent('TransferToAnyAddressConfirmation', {
			certificateId: this.certificate.certificateId,
			issuer: this.certificate.issuer.identity.address,
			network: this.wallet.configuration.networkName,
			recipientAddress: this.address,
		});
	}

	onOpenTx() {
		this.viewTxButton = {
			title: {
				text: this.translateService.instant('TransferCertificate.viewTx'),
			},
			buttonType: 'primary',
			loadingText: null,
			loading: false,
			disabled: false,
			actionFinished: false,
		};
		this.eventLogger.logEvent('ClickedOnViewTxDetails', {
			txHash: this.receipt.receipt.transactionHash,
		});
		this.browserService.openBrowser(
			'https://polygonscan.com/tx/' + this.receipt.receipt.transactionHash,
		);
	}

	checkNext() {
		if (/^(0x)?[0-9a-f]{40}$/i.test(this.address)) {
			this.nextButton.disabled = false;

			this.nextButton = {
				title: {
					text: this.translateService.instant('TransferCertificate.next'),
				},
				buttonType: 'primary',
				loadingText: null,
				loading: false,
				disabled: false,
				actionFinished: false,
			};
		} else {
			this.nextButton = {
				title: {
					text: this.translateService.instant('TransferCertificate.next'),
				},
				buttonType: 'primary',
				loadingText: null,
				loading: false,
				disabled: true,
				actionFinished: false,
			};
		}
	}

	private initButtons() {
		this.confirmButton = {
			title: {
				text: this.translateService.instant('TransferCertificate.confirm'),
			},
			buttonType: 'primary',
			loadingText: null,
			loading: false,
			disabled: false,
			actionFinished: false,
		};

		this.nextButton = {
			title: {
				text: this.translateService.instant('TransferCertificate.next'),
			},
			buttonType: 'primary',
			loadingText: null,
			loading: false,
			disabled: true,
			actionFinished: false,
		};

		this.backButton = {
			title: {
				text: this.translateService.instant('TransferCertificate.back'),
			},
			buttonType: 'primary',
			loadingText: null,
			loading: false,
			disabled: false,
			actionFinished: false,
		};

		this.viewTxButton = {
			title: {
				text: this.translateService.instant('TransferCertificate.viewTx'),
			},
			buttonType: 'primary',
			loadingText: null,
			loading: false,
			disabled: false,
			actionFinished: false,
		};

		this.backToHomeButton = {
			title: {
				text: this.translateService.instant('TransferCertificate.backToHome'),
			},
			buttonType: 'primary',
			loadingText: null,
			loading: false,
			disabled: false,
			actionFinished: false,
		};
	}

	switchStepToConfirm() {
		this.eventLogger.logEvent('TransferToAnyAddressRecap', {
			certificateId: this.certificate.certificateId,
			issuer: this.certificate.issuer.identity.address,
			network: this.wallet.configuration.networkName,
			recipientAddress: this.address,
		});
		this.step = 'confirm';
	}
}
