import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { EventLoggerService } from '../../../providers/event-logger/event-logger-service';
import { Button } from '../../redesign/button/models/button.model';
import { IModalHeaderConfig } from '../components/modal-header/modal-header.component';

@Component({
	selector: 'app-scan-compatible-modal',
	templateUrl: './scan-compatible-modal.component.html',
	styleUrls: ['./scan-compatible-modal.component.scss'],
})
export class ScanCompatibleModalComponent implements OnInit {
	headerConfig: IModalHeaderConfig = {
		logo: './assets/imgs/wallet-connect.png',
		title: 'Scan compatible',
		subtitle: {
			label: 'WalletConnect.compatible.label',
			verified: true,
		},
	};

	getItButton: Button;

	constructor(
		public modalController: ModalController,
		public translateService: TranslateService,
		public eventLogger: EventLoggerService,
	) {
		this.eventLogger.logScreen('walletConnectExplanationModal', {});

		this.headerConfig.title = this.translateService.instant(
			'WalletConnect.compatible.scanCompatible',
		);
	}

	ngOnInit() {
		this.initButtons();
	}

	private initButtons() {
		this.getItButton = {
			title: {
				text: this.translateService.instant('WalletConnect.compatible.getIt'),
			},
			buttonType: 'primary',
		};
	}
}
