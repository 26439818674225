import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
	CommonComponentsModule,
	PipeModule,
	ProductViewModule,
} from '@arianeeprivate/wallet-shared-components';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { MomentModule } from 'ngx-moment';
import { SwiperModule } from 'swiper/angular';

import { IfNotWhitelabelDirective } from '../directives/ifNotWhitelabel/if-not-whitelabel.directive';
import { AuthLegacyComponent } from '../pages/auth/components/auth-legacy/auth-legacy.component';
import { AuthNewComponent } from '../pages/auth/components/auth-new/auth-new.component';
import { BackupImportLegacyComponent } from '../pages/main/backup-import/components/backup-import-legacy/backup-import-legacy.component';
import { BackupImportNewComponent } from '../pages/main/backup-import/components/backup-import-new/backup-import-new.component';
import { BackupSortNewComponent } from '../pages/main/backup-viewer2/components/backup-sort-new/backup-sort-new.component';
import { BackupViewer2LegacyComponent } from '../pages/main/backup-viewer2/components/backup-viewer2-legacy/backup-viewer2-legacy.component';
import { BackupViewerLegacyComponent } from '../pages/main/backup-viewer/components/backup-viewer-legacy/backup-viewer-legacy.component';
import { BackupViewerNewComponent } from '../pages/main/backup-viewer/components/backup-viewer-new/backup-viewer-new.component';
import { BrandListLegacyComponent } from '../pages/main/brand-list/components/brand-list-legacy/brand-list-legacy.component';
import { BrandListNewComponent } from '../pages/main/brand-list/components/brand-list-new/brand-list-new.component';
import { CollectionItemComponent } from '../pages/main/brand-list/components/collection-item/collection-item.component';
import { BrandProductListLegacyPage } from '../pages/main/brand-product-list/components/brand-product-list-legacy/brand-product-list-legacy.page';
import { BrandProductListNewPage } from '../pages/main/brand-product-list/components/brand-product-list-new/brand-product-list-new.page';
import { ChatLegacyComponent } from '../pages/main/chat/components/chat-legacy/chat-legacy.component';
import { ChatNewComponent } from '../pages/main/chat/components/chat-new/chat-new.component';
import { PoapDetailPage } from '../pages/main/poap-detail/poap-detail.page';
import { PoapNftListLegacyComponent } from '../pages/main/poap-nft-list/components/poap-nft-list-legacy/poap-nft-list-legacy.component';
import { PoapNftListNewComponent } from '../pages/main/poap-nft-list/components/poap-nft-list-new/poap-nft-list-new.component';
import { LandingDetailComponent } from '../pages/main/product-detail/components/landing-detail/landing-detail.component';
import { ProductDetailLegacyComponent } from '../pages/main/product-detail/components/product-detail-legacy/product-detail-legacy.component';
import { ProductDetailNewComponent } from '../pages/main/product-detail/components/product-detail-new/product-detail-new.component';
import { BrandAuthenticityModalComponent } from '../pages/main/product-detail/modals/brand-authenticity-modal/brand-authenticity-modal.component';
import { BrandModalComponent } from '../pages/main/product-detail/modals/brand-modal/brand-modal.component';
import { ClaimCongratsModalComponent } from '../pages/main/product-detail/modals/claim-congrats-modal/claim-congrats-modal.component';
import { HistoryDetailsModalComponent } from '../pages/main/product-detail/modals/history-modal/components/history-details-modal/history-details-modal.component';
import { HistoryModalComponent } from '../pages/main/product-detail/modals/history-modal/history-modal.component';
import { TransferAddressModalComponent } from '../pages/main/product-detail/modals/transfer-address-modal/transfer-address-modal.component';
import { TransferChoiceModalComponent } from '../pages/main/product-detail/modals/transfer-choice-modal/transfer-choice-modal.component';
import { QrCodeComponent } from '../pages/main/product-detail/modals/transfer-proof-modal/components/qrcode-generator/qr-code.component';
import { TransferProofModalComponent } from '../pages/main/product-detail/modals/transfer-proof-modal/transfer-proof-modal.component';
import { TransparencyDetailsModalComponent } from '../pages/main/product-detail/modals/transparency-modal/components/transparency-details-modal/transparency-details-modal.component';
import { TransparencyModalComponent } from '../pages/main/product-detail/modals/transparency-modal/transparency-modal.component';
import { BrandNftListComponent } from './brand-nft-list/brand-nft-list.component';
import { DeletePassportComponent } from './delete-passport/delete-passport.component';
import { ExportMnemonicComponent } from './export-mnemonic/export-mnemonic.component';
import { ImportPopoverComponent } from './import-popover/import-popover.component';
import { NetworkWatemarkComponent } from './network-watermark/network-watemark.component';
import { NotiOptionComponent } from './noti-option/noti-option.component';
import { OnboardingSliderItemComponent } from './onboarding-slider/onboarding-slider-item/onboarding-slider-item.component';
import { OnboardingSliderComponent } from './onboarding-slider/onboarding-slider.component';
import { PageHeaderForBrandComponent } from './page-header-for-brand/page-header-for-brand.component';
import { PoapErrorModalComponent } from './poap-error-modal/poap-error-modal.component';
import { PrivacyPolicyPopover } from './privacy-policy-popover/privacy-policy-popover.component';
import { ActionButtonComponent } from './redesign/action-button/action-button.component';
import { BackupChoiceModalComponent } from './redesign/backup-choice-modal/backup-choice-modal.component';
import { BackUpWarnModalComponent } from './redesign/backup-warn-modal/backup-warn-modal.component';
import { ButtonComponent } from './redesign/button/button.component';
import { ChainIconComponent } from './redesign/chain-icon/chain-icon.component';
import { ClaimSliderComponent } from './redesign/claim-swiper/claim-slider.component';
import { CopyIconComponent } from './redesign/copy-component/copy-icon.component';
import { DescriptionModalComponent } from './redesign/description/description-modal/description-modal.component';
import { DescriptionComponent } from './redesign/description/description.component';
import { EllipsisPipe } from './redesign/description/pipes/ellipsis.pipe';
import { EmptyStateComponent } from './redesign/empty-state/empty-state.component';
import { HeaderAltComponent } from './redesign/header-alt/header-alt.component';
import { HeaderComponent } from './redesign/header/header.component';
import { IconComponent } from './redesign/icon/icon.component';
import { ImportChoiceModalComponent } from './redesign/import-choice-modal/import-choice-modal.component';
import { ListItemComponent } from './redesign/list/list-item/list-item.component';
import { ListComponent } from './redesign/list/list.component';
import { ArMediaComponent } from './redesign/media-carousel/components/ar-media/ar-media.component';
import { MediaCarouselFullscreenComponent } from './redesign/media-carousel/components/media-carousel-fullscreen/media-carousel-fullscreen.component';
import { PictureMediaFullscreenComponent } from './redesign/media-carousel/components/picture-media-fullscreen/picture-media-fullscreen.component';
import { PictureMediaComponent } from './redesign/media-carousel/components/picture-media/picture-media.component';
import { UrlEncodePipe } from './redesign/media-carousel/components/picture-media/pipes/url-encode-parenthesis.pipe';
import { ThreeDimensionsMediaComponent } from './redesign/media-carousel/components/three-dimensions-media/three-dimensions-media.component';
import { VideoMediaComponent } from './redesign/media-carousel/components/video-media/video-media.component';
import { YoutubeMediaComponent } from './redesign/media-carousel/components/youtube-media/youtube-media.component';
import { ImageRatioFullWidthDirective } from './redesign/media-carousel/directives/image-ratio-full-width.directive';
import { MediaCarouselComponent } from './redesign/media-carousel/media-carousel.component';
import { BackgroundStylePipe } from './redesign/media-carousel/pipes/background-style.pipe';
import { MessageItemComponent } from './redesign/message-item/message-item.component';
import { ReceptionTimePipe } from './redesign/message-item/pipes/reception-time.pipe';
import { MnemonicSuggestionsComponent } from './redesign/mnemonic-suggestions/mnemonic-suggestions.component';
import { MnemonicTextareaComponent } from './redesign/mnemonic-textarea/mnemonic-textarea.component';
import { ModalCancelButtonComponent } from './redesign/modal-cancel-button/modal-cancel-button.component';
import { ModalScrollableContentComponent } from './redesign/modal-scrollable-content/modal-scrollable-content.component';
import { ModalFooterComponent } from './redesign/modal/modal-footer/modal-footer.component';
import { NetworkSwitchModalComponent } from './redesign/network-switch-modal/network-switch-modal.component';
import { NFTCardComponent } from './redesign/nft-card/nft-card.component';
import { NftStatusComponent } from './redesign/nft-status/nft-status.component';
import { NotificationItemComponent } from './redesign/notification-item/notification-item.component';
import { TimeSinceMessagePipe } from './redesign/notification-item/pipes/time-since-message.pipe';
import { OnBoardingModalComponent } from './redesign/on-boarding/on-boarding-modal.component';
import { OptInOutModalComponent } from './redesign/opt-in-out-modal/opt-in-out-modal.component';
import { TitleComponent } from './redesign/title/title.component';
import { ModalHeaderComponent } from './wallet-connect-modals/components/modal-header/modal-header.component';
import { ModalListWithIconComponent } from './wallet-connect-modals/components/modal-list-with-icon/modal-list-with-icon.component';
import { ConnectToWebsiteModalComponent } from './wallet-connect-modals/connect-to-website-modal/connect-to-website-modal.component';
import { ScanCompatibleModalComponent } from './wallet-connect-modals/scan-compatible-modal/scan-compatible-modal.component';
import { SessionProposalModalComponent } from './wallet-connect-modals/session-proposal-modal/session-proposal-modal.component';
import { WalletConnectedModalComponent } from './wallet-connect-modals/wallet-connected-modal/wallet-connected-modal.component';
import { WalletConnectionLoaderComponent } from './wallet-connect-modals/wallet-connection-loader/wallet-connection-loader.component';
import { WalletErrorModalComponent } from './wallet-connect-modals/wallet-error-modal/wallet-error-modal.component';
import { WalletSignMessageModalComponent } from './wallet-connect-modals/wallet-sign-message-modal/wallet-sign-message-modal.component';
import { WalletSuccessModalComponent } from './wallet-connect-modals/wallet-success-modal/wallet-success-modal.component';
import { WalletSwitchChainModalComponent } from './wallet-connect-modals/wallet-switch-chain-modal/wallet-switch-chain-modal.component';
import { WalletTransactionModalComponent } from './wallet-connect-modals/wallet-transaction-modal/wallet-transaction-modal.component';

const components = [
	PrivacyPolicyPopover,
	NetworkWatemarkComponent,
	ImportPopoverComponent,
	NotiOptionComponent,
	DeletePassportComponent,
	ExportMnemonicComponent,
	BrandNftListComponent,
	PageHeaderForBrandComponent,
	OnboardingSliderComponent,
	OnboardingSliderItemComponent,
	ModalHeaderComponent,
	PoapErrorModalComponent,
	ModalListWithIconComponent,
	ConnectToWebsiteModalComponent,
	WalletConnectedModalComponent,
	WalletConnectionLoaderComponent,
	ScanCompatibleModalComponent,
	WalletSignMessageModalComponent,
	WalletSwitchChainModalComponent,
	WalletTransactionModalComponent,
	WalletErrorModalComponent,
	WalletSuccessModalComponent,
	SessionProposalModalComponent,
	ActionButtonComponent,
	IconComponent,
	DescriptionComponent,
	DescriptionModalComponent,
	IconComponent,
	TitleComponent,
	ListComponent,
	ListItemComponent,
	HeaderComponent,
	MediaCarouselComponent,
	PictureMediaComponent,
	ArMediaComponent,
	YoutubeMediaComponent,
	ThreeDimensionsMediaComponent,
	VideoMediaComponent,
	MediaCarouselFullscreenComponent,
	ButtonComponent,
	BrandModalComponent,
	TransparencyDetailsModalComponent,
	ModalCancelButtonComponent,
	BrandModalComponent,
	TransparencyModalComponent,
	ModalScrollableContentComponent,
	TransferProofModalComponent,
	TransferChoiceModalComponent,
	TransferAddressModalComponent,
	QrCodeComponent,
	ModalScrollableContentComponent,
	ModalFooterComponent,
	BrandAuthenticityModalComponent,
	HistoryModalComponent,
	HistoryDetailsModalComponent,
	ClaimSliderComponent,
	ClaimCongratsModalComponent,
	ProductDetailLegacyComponent,
	ProductDetailNewComponent,
	LandingDetailComponent,
	PoapDetailPage,
	OnBoardingModalComponent,
	CopyIconComponent,
	PictureMediaFullscreenComponent,
	NftStatusComponent,
	BrandProductListNewPage,
	BrandProductListLegacyPage,
	ChainIconComponent,
	NFTCardComponent,
	HeaderAltComponent,
	PoapNftListLegacyComponent,
	PoapNftListNewComponent,
	EmptyStateComponent,
	BrandListLegacyComponent,
	BrandListNewComponent,
	CollectionItemComponent,
	ChatLegacyComponent,
	ChatNewComponent,
	NotificationItemComponent,
	MessageItemComponent,
	OptInOutModalComponent,
	AuthLegacyComponent,
	AuthNewComponent,
	NetworkSwitchModalComponent,
	MnemonicSuggestionsComponent,
	MnemonicTextareaComponent,
	ImportChoiceModalComponent,
	BackupChoiceModalComponent,
	BackupImportNewComponent,
	BackupImportLegacyComponent,
	BackupViewerLegacyComponent,
	BackupViewerNewComponent,
	BackupViewer2LegacyComponent,
	BackupSortNewComponent,
	BackUpWarnModalComponent,
];

export function playerFactory() {
	return player;
}

@NgModule({
	imports: [
		CommonModule,
		IonicModule,
		TranslateModule,
		MomentModule,
		FormsModule,
		PipeModule,
		CommonComponentsModule,
		SwiperModule,
		ProductViewModule,
		ReactiveFormsModule,
		LottieModule.forRoot({ player: playerFactory }),
	],
	declarations: [
		...components,
		EllipsisPipe,
		ImageRatioFullWidthDirective,
		BackgroundStylePipe,
		IfNotWhitelabelDirective,
		UrlEncodePipe,
		TimeSinceMessagePipe,
		ReceptionTimePipe,
	],
	exports: components,
	entryComponents: components,
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
