import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

@Injectable({
  providedIn: 'root'
})
export class SharingService {
  constructor (private socialSharing: SocialSharing, private translateService: TranslateService) {
  }

  /**
   *
   * @param subject: translation key
   * @param message: translation key
   * @param url: url to share
   */
  public shareLink = (subject:string, message:string, url:string) => {
    // this is the complete list of currently supported params you can pass to the plugin (all optional)

    forkJoin(
      {
        subject: this.translateService.get(subject),
        message: this.translateService.get(message)
      }
    )
      .pipe(
        take(1)
      )
      .subscribe((data) => {
        const options = {
          subject: data.subject, // fi. for email
          message: data.message, // not supported on some apps (Facebook, Instagram)
          url: url
        };

        this.socialSharing.shareWithOptions(options);
      });
  };
}
