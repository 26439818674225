import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AmplitudeClient, getInstance } from 'amplitude-js';
import { ProfileService } from '../profile-service/profile.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { take } from 'rxjs/operators';

declare const window: Window & { cordova: any };

export enum IOSTrackingPermission {
  TRACKING_PERMISSION_NOT_DETERMINED = 0,
  TRACKING_PERMISSION_RESTRICTED = 1,
  TRACKING_PERMISSION_DENIED = 2,
  TRACKING_PERMISSION_AUTHORIZED = 3,
}

@Injectable({
  providedIn: 'root'
})

export class EventLoggerService {
  private client: AmplitudeClient;
  private queueLogs:Array<{eventName, params}>=[];
  private userId: any;

  private iosTrackingPermission: IOSTrackingPermission = IOSTrackingPermission.TRACKING_PERMISSION_NOT_DETERMINED;

  constructor (
    private platform: Platform,
    private profileService:ProfileService,
    private activatedRoute:ActivatedRoute
  ) {

  }

  public init = async (userId?) => {
    const instance = getInstance();

    const userAllowTracking = this.userAllowTracking();

    instance.init('afb728e29bc8aeb37dab8e13f353c3fd', null, {
      cookieExpiration: 395
    });

    if (!userId) {
      if (instance.options.userId) {
        this.userId = instance.options.userId;
      } else {
        this.userId = this.uuid();
      }
    } else {
      this.userId = userId;
    }

    if (!instance.options.userId) {
      instance.setUserId(this.userId);
    }

    const amplitudeEndpoint = 'https://api2.amplitude.com';
    instance.options.apiEndpoint = amplitudeEndpoint.replace('https://', '');

    this.profileService.getProfile()
      .subscribe(async (profile) => {
        instance.setVersionName(profile.versionCode.toString());

        await this.updateUserProperties();

        this.client = instance;
        this.queueLogs.forEach((i) => this.logEvent(i.eventName, i.params));

        this.queueLogs = [];
      });
  };

  private async updateUserProperties () {
    const profile = await this.profileService.getProfile().pipe(take(1)).toPromise();

    const instance = getInstance();

    const idfa = await this.getIdfa();
    const trackingRefused = !this.userAllowTracking();

    instance.setUserProperties({
      ...profile,
      idfa,
      trackingRefused
    });
  }

  private uuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  public getUserId () {
    return this.userId;
  }

  public logScreen = (screenName: string, params: any = {}) => {
    this.logEvent('SCREEN_' + screenName, params);
  }

  public logEvent=async (eventName: string, params?: any) => {
    if (environment.production) {
      if (!this.client) {
        this.queueLogs.push({ eventName, params });
      } else {
        const queryParams = await this.activatedRoute.queryParams.pipe(take(1)).toPromise();

        this.client.logEvent(eventName, {
          ...queryParams,
          ...params
        });
      }
    } else {
      console.info(eventName, params);
    }
  }

  private userAllowTracking () {
    if (this.platform.is('ios')) {
      return this.iosTrackingPermission === IOSTrackingPermission.TRACKING_PERMISSION_AUTHORIZED;
    }

    return true;
  }

  /**
   * Get IDFA (identifier for advertisers)
   */
  private async getIdfa (): Promise<string> {
    if (!this.platform.is('cordova') || !window.cordova) return 'IDFA00000000-0000-0000-0000-000000000000';

    const info = await window.cordova.plugins.idfa.getInfo();
    return info.idfa || info.aaid;
  }

  // #region iOS Privacy
  /**
   * Success callback method for iOS IDFA permission request
   * @param trackingPermission a number that indicates the permission the user gave to the app
   */
  public idfaRequestPermissionSuccess (trackingPermission: IOSTrackingPermission) {
    if (!this.platform.is('ios')) return;

    this.iosTrackingPermission = trackingPermission;
    this.updateUserProperties();
  }

  /**
   * Failure callback method for iOS IDFA permission request
   * @param payload error payload
   */
  public idfaRequestPermissionFailure (payload: any) {
    if (!this.platform.is('ios')) return;

    this.iosTrackingPermission = IOSTrackingPermission.TRACKING_PERMISSION_NOT_DETERMINED;
    console.error('IDFA request permission failed, details:', payload);
  }
  // #endregion
}
