import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NETWORK } from '@arianee/arianeejs';
import { CertificateSummary } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary';
import { get } from 'lodash';

import { environment } from '../../../../../../environments/environment';
import { EnsService } from '../../../../../providers/ens-service/ens.service';
import { EventLoggerService } from '../../../../../providers/event-logger/event-logger-service';
import { FeatureFlipService } from '../../../../../providers/feature-flip-service/feature-flip.service';
import LandingClaimService, {
	ClaimParams,
} from '../../../../../providers/landing-claim-service/landing-claim.service';
import { LensService } from '../../../../../providers/lens-service/lens.service';
import { CustomValidator } from '../../../../../shared/public-key.validator';

interface LandingDetailProps {
	brandLogoUrl: string;
	certificate: CertificateSummary;
	onDownloadAppClick: EventEmitter<'appStoreButton' | 'googlePlayButton'>;
}

@Component({
	selector: 'app-landing-detail',
	templateUrl: './landing-detail.component.html',
	styleUrls: ['./landing-detail.component.scss'],
})
export class LandingDetailComponent
	implements OnInit, OnChanges, LandingDetailProps, AfterViewInit
{
	@Input() brandLogoUrl: LandingDetailProps['brandLogoUrl'];
	@Input() certificate: LandingDetailProps['certificate'];

	@Input() isFullscreen: boolean = false;
	@Input() displayDownloadButton: boolean = false;
	@Output() onDownloadAppClick: LandingDetailProps['onDownloadAppClick'] =
		new EventEmitter();

	public isWeb: boolean = environment.appType === 'web';
	public pageUrl: string;

	public claimForm = this.formBuilder.group({
		publicKey: [
			'',
			[Validators.required],
			[this.customValidator.validate.bind(this.customValidator)],
		],
	});

	public allowClaimWithAddress = false;
	public footerToShow: 'claim_with_address' | 'download' = 'download';

	public isLoading = false;
	public claimState:
		| 'idle'
		| 'claiming'
		| 'success'
		| 'error'
		| 'alreadyClaimed' = 'idle';

	private addressType: 'publicKey' | 'ens' | 'lens' = 'publicKey';

	constructor(
		private formBuilder: FormBuilder,
		private featureFlipService: FeatureFlipService,
		private landingClaimService: LandingClaimService,
		private activatedRoute: ActivatedRoute,
		private eventLoggerService: EventLoggerService,
		private ensService: EnsService,
		private lensService: LensService,
		private customValidator: CustomValidator,
	) {}

	ngOnInit(): void {
		const _pageUrl = new URL(window.location.href);
		_pageUrl.searchParams.append('s', '');

		this.pageUrl = _pageUrl.toString();

		this.setAllowClaimWithAddress();
	}

	ngAfterViewInit() {
		this.claimForm.statusChanges.subscribe((status) => {
			this.setLoading(status === 'PENDING');

			if (['success', 'error'].includes(this.claimState)) {
				this.claimState = 'idle';
			}
		});
	}

	ngOnChanges() {
		this.setAllowClaimWithAddress();
	}

	private async setAllowClaimWithAddress() {
		const isOnPolygon = ![NETWORK.mainnet, NETWORK.testnet].includes(
			environment.network,
		);
		const isNotProof =
			this.activatedRoute.snapshot.paramMap.get('method') !== 'proof';
		const isClaimWithAddressFeatureFlipOn = await this.featureFlipService
			.$isFeatureFlipOnce('landingClaimWithAddress')
			.toPromise();

		this.allowClaimWithAddress =
			isOnPolygon && isClaimWithAddressFeatureFlipOn && isNotProof;
	}

	public pastePublicKey() {
		navigator.clipboard.readText().then((publicKey) => {
			this.claimForm.get('publicKey').setValue(publicKey);
		});
	}

	public setLoading(loading: boolean) {
		this.isLoading = this.claimState === 'claiming' || loading;
	}

	private getAddress() {
		return this.claimForm.get('publicKey').value;
	}

	private getAddressType(): 'publicKey' | 'ens' | 'lens' {
		return this.addressType;
	}

	private getIssuerAddress() {
		return get(this.certificate, 'issuer.identity.address', null);
	}

	private async convertEnsOrLensToAddress(address: string) {
		const [addressFromEns, addressFromLens] = await Promise.all([
			this.ensService.resolveENS(address),
			this.lensService.getProfilesByHandle(address),
		]);

		if (addressFromEns) {
			this.addressType = 'ens';
			return addressFromEns;
		}

		if (addressFromLens) {
			this.addressType = 'lens';
			return addressFromLens;
		}

		this.addressType = 'publicKey';
		return null;
	}

	public getClaimButtonStyle(defaultStyle: {
		background: string;
		color: string;
	}): {
		background: string;
		color: string;
	} {
		if (this.claimState === 'success') {
			return {
				background: getComputedStyle(document.documentElement).getPropertyValue(
					'--color-bg-success',
				),
				color: getComputedStyle(document.documentElement).getPropertyValue(
					'--color-fg-success',
				),
			};
		} else if (
			this.claimState === 'error' ||
			this.claimState === 'alreadyClaimed'
		) {
			return {
				background: getComputedStyle(document.documentElement).getPropertyValue(
					'--color-bg-danger',
				),
				color: getComputedStyle(document.documentElement).getPropertyValue(
					'--color-fg-danger',
				),
			};
		}

		return defaultStyle;
	}

	async onClaimSubmit(): Promise<void> {
		this.claimState = 'claiming';
		this.setLoading(true);

		let address = this.getAddress();

		const addressFromEnsOrLens = await this.convertEnsOrLensToAddress(address);
		if (addressFromEnsOrLens) {
			address = addressFromEnsOrLens;
		}

		const certificateIdAndPassphrase =
			this.activatedRoute.snapshot.paramMap.get('certificateIdAndpassphrase');

		const [certificateId, passphrase] = certificateIdAndPassphrase.split(',');

		let claimParams: ClaimParams;

		try {
			claimParams = await this.landingClaimService.retrieveClaimParamsBehindNft(
				address,
				this.certificate,
				{
					tokenId: +certificateId,
					passphrase,
				},
			);
		} catch (e) {
			console.error('Could not retrieve the claim params behind the nft\n', e);
			this.claimState = 'error';
			this.setLoading(false);
			return;
		}

		const isRequstable = await this.landingClaimService.isNftClaimable(
			environment.network,
			claimParams,
		);

		if (!isRequstable.isTrue) {
			console.error('This NFT is not claimable');
			this.claimState = 'alreadyClaimed';
			this.setLoading(false);
			return;
		}

		const issuerAddress = this.getIssuerAddress();
		const addressType = this.getAddressType();

		const analyticsParams = {
			certificateId: +certificateId,
			addressType,
			addressValue: address,
			issuerAddress,
			network: environment.network,
		};

		try {
			await this.landingClaimService.relayedClaim(
				environment.network,
				address,
				claimParams,
			);
			this.eventLoggerService.logEvent(
				'landing_claim_with_address_success',
				analyticsParams,
			);
			this.claimForm.reset();
			this.claimState = 'success';
		} catch (e) {
			console.error(e);
			this.claimState = 'error';
			this.eventLoggerService.logEvent(
				'landing_claim_with_address_error',
				analyticsParams,
			);
		}

		this.setLoading(false);
	}
}
