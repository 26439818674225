import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
} from '@angular/core';
import { ModalController } from '@ionic/angular';

import { BrowserService } from '../../../../providers/inapp-browser/inapp-browser-service';
import { Icon } from '../../icon/models/icon.model';
import { ListItem } from '../../list/models/list-item.model';

@Component({
	selector: 'app-description-modal',
	templateUrl: './description-modal.component.html',
	styleUrls: ['./description-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DescriptionModalComponent implements OnInit {
	@Input() icon?: Icon;
	@Input() title: string;
	@Input() description: string;
	@Input() propertiesItems: ListItem[] = undefined;
	@Input() isWeb: boolean;

	public modal: HTMLIonModalElement;

	constructor(
		private modalCtrl: ModalController,
		private browserService: BrowserService,
	) {
		document.onclick = this.interceptHref;
	}

	async ngOnInit() {
		this.modal = await this.modalCtrl.getTop();
	}

	onCloseModal(): void {
		this.modalCtrl.dismiss({
			dismissed: true,
		});
	}

	private interceptHref = (event: MouseEvent) => {
		const target = event.target as HTMLElement;
		if (target.tagName === 'A') {
			event.preventDefault();
			const href = target.getAttribute('href');
			this.browserService.openBrowserSystem(href);
		}
	};
}
