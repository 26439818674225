import Core from '@walletconnect/core';
import { CoreTypes } from '@walletconnect/types';
import { getSdkError } from '@walletconnect/utils';
import { formatJsonRpcError, formatJsonRpcResult } from '@json-rpc-tools/utils';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import Client, { IWeb3Wallet as IClient, Web3WalletTypes as IClientTypes } from '@walletconnect/web3wallet';
import { ArianeeService } from '../arianee-service/arianee.service';
import { take } from 'rxjs/operators';
import { utils } from 'ethers';

export enum SupportedChain {
  ETHEREUM = 'eip155:1',
  SOKOL = 'eip155:77',
  POA = 'eip155:99',
  POLYGON = 'eip155:137',
  MUMBAI = 'eip155:80001'
}

const SUPPORTED_METHODS: string[] = [
  'eth_sendTransaction',
  'eth_sign',
  'eth_signTypedData',
  'eth_signTransaction',
  'personal_sign',
  'wallet_switchEthereumChain'
];

@Injectable({
  providedIn: 'root'
})
export default class WalletConnectV2Utils {
  constructor (private arianeeService: ArianeeService) {}

  private core: Core;
  client: IClient;

  public getCore (): Core {
    if (!this.core) {
      this.core = new Core({
        projectId: environment.walletConnectV2.projectId
      });
    }

    return this.core;
  }

  public async getClient (): Promise<IClient> {
    if (!this.client) {
      this.client = await Client.init({
        core: this.getCore(),
        metadata: environment.walletConnectV2.metadata
      });
    }

    return this.client;
  }

  public approveEIP155Request (
    requestEvent: IClientTypes.EventArguments['session_request'],
    result: any
  ) {
    const { id } = requestEvent;
    return formatJsonRpcResult(id, result);
  }

  public rejectEIP155Request (
    requestEvent: IClientTypes.EventArguments['session_request'],
    sdkError: ReturnType<typeof getSdkError>
  ) {
    const { id } = requestEvent;
    return formatJsonRpcError(id, sdkError.message);
  }

  public getSignParamsMessage (
    signRequestEvent: IClientTypes.EventArguments['session_request']
  ) {
    const { request } = signRequestEvent.params;
    const { params } = request;

    let plainMessage: string;
    const data = params.filter((param) => !utils.isAddress(param))[0];
    if (['eth_signTypedData', 'eth_signTypedData_v3', 'eth_signTypedData_v4'].includes(request.method)) {
      plainMessage = typeof data !== 'string' ? JSON.stringify(data) : data;
    } else {
      plainMessage = utils.isHexString(data) ? utils.toUtf8String(data) : data;
    }

    return plainMessage;
  }

  public getSupportedChains (): string[] {
    return [...Object.values(SupportedChain)];
  }

  public getSupportedMethods (): string[] {
    return [...SUPPORTED_METHODS];
  }

  /**
   * @returns the `iss` param used by various Web3Wallet methods (e.g. formatMessage(cacaoPayload, iss))
   */
  public async getIss (): Promise<string> {
    const address = await this.arianeeService.$address.pipe(take(1)).toPromise();
    return `did:pkh:eip155:1:${address}`;
  }

  public async isVerifiedPeer (peerMetadata: CoreTypes.Metadata): Promise<boolean> {
    const verifiedPeers: { url: string }[] = await (
      await fetch(environment.walletConnectVerified)
    ).json();

    const peerUrl = peerMetadata.url.startsWith('http') ? new URL(peerMetadata.url).hostname : peerMetadata.url;

    return !!verifiedPeers.find(
      (verifiedPeer) => verifiedPeer.url.replace(/\//g, '') === peerUrl
    );
  }
}

export { parseUri } from '@walletconnect/utils';
