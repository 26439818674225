import { ListItem } from '../../../../../../components/redesign/list/models/list-item.model';
import { MappedCertificate } from '../../../models/mapped-certificate.model';
import { dateFormatter } from '../../date/date-formatter';

export function transferEventsMapper(
	certificate: MappedCertificate,
	events,
): ListItem[] {
	return (events || [])
		.filter((event) => event.event === 'Transfer')
		.map((event) => {
			return {
				icon: {
					image: {
						url: event.identity.data
							? event.identity.data.pictures.find(
									(picture) => picture.type === 'brandLogoSquare',
							  ).url
							: null,
						height: '48px',
						width: '48px',
						borderRadius: '24px',
					},
				},
				jazzIcon: !event.identity.data
					? { isEnabled: true, data: event.identity.address }
					: null,
				title: event.title,
				highlightText: event.highlightText,
				subtitle: event.timestamp
					? dateFormatter(new Date(event.timestamp * 1000))
					: undefined,
				actionIcon: 'none',
				action: () => undefined,
				e2eTag: 'transfer-history-item',
			};
		});
}
